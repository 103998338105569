import React, { useState } from 'react';
import { StyleSheet, ScrollView, Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'i18n-js';
import moment from "moment/min/moment-with-locales"
import AsyncStorage from '@react-native-community/async-storage';

import {SUPORTED_LOCALES} from '@utilities/locale'
import { ButtonText, SimpleText } from '@quarks'
import { sessionOperations } from '_ducks/session'
import { AppStyle, TextStyle } from "@style";
import { useAuth } from '@realm'
import { ILLUSTRATIONS } from '@images'

const EmailVerification = (props) => {

    const styles = updateStyles()
    const dispatch = useDispatch()

    const [state, setState] = useState({
        title: '$access.emailVerification.title',
        description: ''
    })
    const { confirmUser, loading } = useAuth()

    const locale = props.route.params.locale
    const token = props.route.params.token
    const tokenId = props.route.params.tokenId

    React.useEffect(() => {
        if (SUPORTED_LOCALES.includes(locale)) {
            i18n.locale = locale
            moment.locale(i18n.locale)
            AsyncStorage.setItem('@nadius:locale', locale)
            dispatch(sessionOperations.updateLanguage(locale))
        }
    }, [locale])

    React.useEffect(() => {
        const executeConfirmation = async () => {
            let code = await confirmUser(token, tokenId);
            if (code) {
                switch (code) {
                    case 400:
                      setState({
                          title: "$access.emailVerification.errorTitle",
                          description: "$access.emailVerification.invalidToken"
                      })
                      break;
                    case 404:
                      setState({
                          title: "$access.emailVerification.unexisting",
                          description: "$access.emailVerification.invalidToken"
                      })
                      break;
                    default:
                        setState({
                            title: "$common.errors.unknownReduced",
                            description: "$common.errors.unknownRetry",
                        })
                      break;
                  }
            } else {
                setState({
                    success: true,
                    title: "$access.emailVerification.successTitle",
                    description: "$access.emailVerification.successDescription"
                })
            }
        }
        executeConfirmation()
    }, [token, tokenId]);
    
    // In order to force a view update
    useSelector(state => state.session.language)

    const _onPress = () => {
        props.navigation.navigate('AccessLogin')
    }

    return (
        <ScrollView style={{
            flex: 1,
            width: '100%',
            alignSelf: 'center',
            backgroundColor: AppStyle.backgroundColor
        }}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}>

            <SimpleText style={styles.welcomeTitle}>
                {state.title}
            </SimpleText>
            <Image
                style={{
                    height: '40%', maxHeight: 180,
                    width: '100%', maxWidth: 400, alignSelf: 'center'
                }}
                source={ILLUSTRATIONS.confirm}
            />
            <SimpleText style={styles.verificationText}>
                {state.description}
            </SimpleText>
            {state.success && <ButtonText
                onPress={_onPress}
                text={'$access.emailVerification.goToLogin'}
                type={'solid'}
                disabled={loading}
                style={{
                    marginTop: 20,
                    height: 50,
                    width: '60%',
                    textAlign: 'center'
                }} />}
        </ScrollView>
    )
}


const updateStyles = () => StyleSheet.create({
    welcomeTitle: {
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center'
    },
    emailText: {
        ...TextStyle.size.large,
        ...TextStyle.weight.bold,
        paddingTop: 10,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    verificationText: {
        paddingTop: 20,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.size.large
    },
    secondaryText: {
        paddingTop: 30,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        color: AppStyle.textColorLight,
        ...TextStyle.size.medium
    }
});

export default EmailVerification;