import React from 'react';
import { View, StyleSheet, ScrollView, useWindowDimensions, Platform, ImageBackground } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'
import { createStackNavigator, useHeaderHeight } from '@react-navigation/stack';

import { codePush, Alert } from "@platform";

import { SimpleText, ButtonText } from '@quarks'
import { AppStyle, TextStyle } from '@style'
import { MAIN_BACKGROUND } from '_images'
import { REGISTER_STATUS } from '_enums'
import { useAuth } from '@realm'
import { APP_ICONS } from '@images'
import { NavigationContainer } from '@react-navigation/native';
import AccessScreens from '_scenes/access';
import EmailVerificationScreen from './emailVerification';
import ResetPasswordScreen from './resetPassword';

import LoginScreen from "./login"
import RegisterScreen from "./register"
import VerificationScreen from "./verification"
import ForgotPasswordScreen from './forgotPassword';

export const WelcomeScreen = (props) => {

    const dispatch = useDispatch()
    const styles = updateStyles();
    const register = useSelector(state => state.session.register)
    const language = useSelector(state => state.session.language)

    const { user } = useAuth();
    const [appUpdate, setAppUpdate] = React.useState(undefined)
    const [viewHeight, setHeight] = React.useState(450)
    const dimensions = useWindowDimensions()
    const SubAccessStack = createStackNavigator();

    let status = {
        text: '$access.index.text',
        preferredLogin: false,
        showImage: true,
        downloadText: '$access.index.downloadText',
        downloadButtonText: '$access.index.downloadButtonText'
    }

    switch (register.status) {
        case REGISTER_STATUS.WAITING_VERIFICATION:
            status.text = "$access.index.textRegistered".transFormat({ email: typeof register.form?.email !== 'undefined' ? register.form?.email : '' }),
                status.preferredLogin = true
            status.showImage = false
            status.downloadText = "$access.index.downloadTextRegistered"
            status.downloadButtonText = "$access.index.downloadButtonTextRegistered"
            break;
        default:
            break;
    }

    const _onRegister = () => {
        if (register.status === REGISTER_STATUS.WAITING_VERIFICATION) {
            Alert(dispatch).alert(
                "$access.index.registerAlert.title".localise(),
                "$access.index.registerAlert.description".transFormat({ email: register.form?.email }),
                [
                    {
                        text: '$access.index.registerAlert.cancelButton'.localise(),
                        style: 'cancel',
                    },
                    {
                        text: '$access.index.registerAlert.confirmButton'.localise(),
                        onPress: () => {
                            props.navigation.navigate('AccessRegister')
                        }

                    },
                ],
                { cancelable: true },
            );
        } else {
            props.navigation.navigate('AccessRegister')
        }

    }

    React.useEffect(() => {
        if (props.navigation.isFocused()) {
            const getUpdate = async () => {
                setAppUpdate(await codePush.getUpdateMetadata?.())
            }
            getUpdate()
        }
    }, [props.navigation])

    const navigate = () => {
        props.navigation.reset({
            index: 0,
            routes: [{ name: 'Main' }],
        });
    }

    if ((props.navigation.isFocused() || Platform.OS == 'web') &&
        user && user.email) {
        if (user.producer) {
            navigate()
        } else if (typeof user.toBecomeProducer !== 'undefined') {
            props.navigation.navigate('WaitingValidation')
        } else {
            props.navigation.reset({
                index: 0,
                routes: [{ name: 'Login' }],
            });
        }
    }

    const baseOptions = {
        headerTintColor: AppStyle.textHeaderColor,
        headerTransparent: true,
        animationEnabled: false,
        headerStyle: {
            backgroundColor: 'transparent',
            elevation: 0,
            shadowOpacity: 0,
            borderBottomWidth: 0
        },
        headerTitle: '',
    }

    const mainOptions = ({ route, navigation }) => {
        return {
            ...baseOptions,
            headerTransparent: false,
            headerTitle: (route.name == 'AccessLogin' || route.name == 'AccessRegister') &&
                (() => (<View style={{
                    flexDirection: 'row', justifyContent: 'space-around',
                    flex: 1
                }}>
                    <View style={{
                        flex: 1,
                    }}>
                        <ButtonText
                            onPress={() => {
                                navigation.replace('AccessLogin')
                                setHeight(450)
                            }}
                            textStyle={{
                                ...TextStyle.size.xlarge,
                                color: route.name != 'AccessLogin' ? AppStyle.getColor('dark', 3) : AppStyle.mainColor
                            }}
                            text={'$access.index.loginButton'} />
                        {route.name == 'AccessLogin' && <View style={{
                            borderBottomColor: AppStyle.mainColor, marginTop: 10,
                            borderBottomWidth: 3, width: '70%', alignSelf: 'center'
                        }} />}
                    </View>
                    <View style={{
                        flex: 1,
                    }}>
                        <ButtonText
                            onPress={() => {
                                navigation.replace('AccessRegister')
                                setHeight(650)
                            }}
                            textStyle={{
                                ...TextStyle.size.xlarge,
                                color: route.name != 'AccessRegister' ? AppStyle.getColor('dark', 3) : AppStyle.mainColor
                            }}
                            text={'$access.index.registerButton'} />
                        {route.name == 'AccessRegister' && <View style={{
                            borderBottomColor: AppStyle.mainColor, marginTop: 10,
                            borderBottomWidth: 3, width: '70%', alignSelf: 'center'
                        }} />}
                    </View>
                </View>))
        }
    }
    const content = (<NavigationContainer 
    initialState={props.route?.state}
    independent={true} theme={{
        colors: {
            background: 'transparent'
        }
    }} >
        <SubAccessStack.Navigator
            screenOptions={({ route, navigation }) => {
                switch (route.name) {
                    case "AccessLogin":
                    case "AccessResetPassword":
                        setHeight(450)
                        break;
                    case "AccessEmailVerification":
                        setHeight(550)
                        break;
                    case "AccessVerification":
                    case "AccessRegister":
                        setHeight(650)
                        break;
                    default:
                        setHeight(500)
                        break;
                }
                return ({
                    ...baseOptions,
                    title: `Nadius - ${`$root.screens.${route.name}`.localise()}`,
                })
            }}>
            <SubAccessStack.Screen
                name="AccessLogin"
                component={LoginScreen}
                options={mainOptions}
            />
            <SubAccessStack.Screen
                name="AccessRegister"
                component={RegisterScreen}
                options={mainOptions}
            />
            <SubAccessStack.Screen
                name="AccessForgot"
                component={ForgotPasswordScreen}
                options={baseOptions}
            />
            <SubAccessStack.Screen
                name="AccessVerification"
                component={VerificationScreen}
                options={baseOptions}
            />
            <SubAccessStack.Screen
                name="AccessEmailVerification"
                component={EmailVerificationScreen}
                options={baseOptions}
            />
            <SubAccessStack.Screen
                name="AccessResetPassword"
                component={ResetPasswordScreen}
                options={baseOptions}
            />
        </SubAccessStack.Navigator>
    </NavigationContainer>
    )

    const headerHeight = useHeaderHeight();
    const addBackgroundImage = dimensions.width > 600 && dimensions.height > 700

    return (<ScrollView
        key={language}
        style={styles.main}
        contentContainerStyle={styles.mainContent}>

        {addBackgroundImage && <ImageBackground source={MAIN_BACKGROUND}
            style={styles.image}>
            <View style={[{
                width: 500, backgroundColor: AppStyle.backgroundColor,
                borderRadius: 20, height: viewHeight, paddingBottom: 20,
                alignSelf: 'center', paddingTop: 20, justifyContent: 'center',
            }, styles.shadow]}>
                {content}
            </View>
        </ImageBackground>}
        {!addBackgroundImage && <View style={[{
            flex: 1, top: headerHeight, paddingTop: 20, width: '100%',
            backgroundColor: AppStyle.backgroundColor, paddingBottom: 20,
            borderRadius: 20, height: dimensions.height, alignSelf: 'center',
            justifyContent: 'center', ...styles.shadowLight
        }, styles.shadowLight]}>
            {content}
        </View>}

        {appUpdate && <SimpleText
            style={{
                color: AppStyle.textColorLight,
                ...TextStyle.size.mini,
                textAlign: 'center',
                marginTop: 20,
                alignSelf: 'center',
                position: 'absolute',
                bottom: 10,
                right: 30
            }}>
            {appUpdate.appVersion} {appUpdate.label}
        </SimpleText>}
    </ScrollView>
    )
};

const updateStyles = () => StyleSheet.create({
    cardView: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    titleView: {
        paddingBottom: 20,
        width: '90%',
        alignSelf: 'center',
    },
    descriptionView: {
        paddingTop: 50,
        width: '90%',
        alignSelf: 'center'
    },
    image: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center"
    },
    shadow: {
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.3,
        shadowRadius: 50,
        elevation: 4,
    },
    shadowLight: {
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.1,
        shadowRadius: 50,
        elevation: 1,
    },
    main: {
        height: '100%',
    },
    mainContent: {
        alignContent: 'center',
        justifyContent: 'center',
        flexGrow: 1
    }
});