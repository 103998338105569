import { TextStyle } from '@style';
import React, { Component } from 'react';
import {
    View,
    ViewPropTypes,
    TouchableOpacity,
    StyleSheet,
} from 'react-native';
import { ButtonText } from '../quarks/buttonText';
import { SimpleText } from '../quarks/simpleText';


export const BreadCrumb = (props) => {
    
    return (
        <View style={[styles.containerStyle, props.style]}>
            {
                props.entities.map((item, index) => {
                    return (
                        <View key={item._id} style={{ flexDirection: 'row', height: 45 }}>
                            { index < (props.entities.length - 1) &&
                                <ButtonText
                                    text={item.name}
                                    type={'outline'}
                                    style={{paddingLeft: 10, paddingRight: 10}}
                                    onPress={() => props.onPress(item)}
                                />
                            }
                                <SimpleText style={{paddingRight: index == props.entities?.length - 1 ? 10 : 0 }}> {index < (props.entities.length - 1) ? ' > ' : item.name} </SimpleText>
                                
                        </View>
                    );
                })
            }
        </View>
    );
};

const styles = StyleSheet.create({
    containerStyle: {
        flexDirection: 'row'
    }
});

