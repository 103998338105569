import * as Localization from "expo-localization";
import i18n from "i18n-js";
import moment from "moment/min/moment-with-locales"
import { translations } from "@translations"

const sameElse = (union = "de") => (now) => {
    if (moment(Date(this)).year() === now.year()) {
        return `D [${union}] MMMM`;
    } else {
        return "LL";
    }
}

moment.updateLocale("ca", {
    calendar: {
        lastDay: "[Ahir a les] LT",
        sameDay: "[Avui a les] LT",
        nextDay: "[Demà a les] LT",
        lastWeek: "dddd [passat]",
        nextWeek: "dddd [que ve]",
        sameElse: sameElse()
    }
});
moment.updateLocale("es", {
    calendar: {
        lastDay: "[Ayer a las] LT",
        sameDay: "[Hoy a las] LT",
        nextDay: "[Mañana a las] LT",
        lastWeek: "dddd [pasado]",
        nextWeek: "dddd [que viene]",
        sameElse: sameElse()
    }
});

moment.updateLocale("en", {
    calendar: {
        lastDay: "[Yesterday at] LT",
        sameDay: "[Today at] LT",
        nextDay: "[Tomorrow at] LT",
        lastWeek: "[last] dddd",
        nextWeek: "[upcoming] dddd",
        sameElse: sameElse("of")
    }
});

i18n.translations = {
    ca: {
        ...translations("ca"),
        products: {
            title: "Els teus productes",
            disabled: "Inactiu",
            addButton: "Afegir un nou",
        },
        update: {
            mainTitle: "Actualitzant",
            downloading: "Descarregant",
            installing: "instal·lant ...",
            restart: "Reiniciant ..."
        },
        orders: {
            tableTitle: 'Comandes',
            tableOrder: 'Comanda',
            tableDestination: 'Destí',
            tableTotal: 'Total',
            tableStatus: 'Estat',

            notArrived: "No ha arribat",
            unwantedProducts: "Productes no corresponen",
            badStatus: "En mal estat",
            other: "Altres",
            seeAll: "Veure totes les comandes",
            summary: "Resum",

            preparingReady: "Per preparar",
            consolidationReady: "En centre logistic",

            products: "Productes",
            total: "Total",
            pickup: "Recollida",

            subTitlePreFix: "Tens",
            subTitleSufixDisputes: "incidències",
            subTitleSufixDispute: "incidència",
            subTitleNewOrder: "comanda nova",
            subTitleNewOrders: "comandes noves",
            subTitleLeft: "Queden",
            subTitleToDeliver: "per lliurar",
            everythingOk: "Tot en ordre",

            seeMore: "Veure més",
            disputesTitle: "Incidències",
            pendingTitle: "Pendents de resposta",
            preparingTitle: "En preparació",
            transitTitle: "En trànsit o per recollir",
            noneNow: "No hi ha actualment"
        },
        button: {
            add: "Afegir",
            noneLeft: "Esgotat",
            save: "Desar",
            done: "Fet",
            seeMore: "Veure més",
            cancel: "Cancel·lar",
            delete: "Eliminar",
            all: "Tot",
            edit: "Modificar",
            addOrRemoveTranslation: "Llenguatges",
            headerTruncatedBack: "Enrere"
        },
        access: {
            emailVerification: {
                invalidToken: "No hem pogut confirmar el teu compte a partir d'aquest enllaç, l'has obert correctament?",
                errorTitle: "Hem detectat un problema...",
                title: "Validant el teu compte...",
                successTitle: "El teu compte s'ha verificat correctament!",
                successDescription: "Ja pots iniciar sessió a la plataforma",
                unexisting: "L'enllaç no és vàlid",
                goToLogin: "Iniciar sessió"
            },
            resetPassword: {
                title: "Restablir contrasenya",
                new: "Nova contrasenya",
                newPlaceholder: "········",
                submitButton: "Canviar-la",
                repeat: "Torna a escriure-la",
                retypePasswordPlaceholder: "········",

                successTitle: "La contrasenya s'ha modificat correctament!",
                successDescription: "Ja pots iniciar sessió a la plataforma",

                goToLogin: "Iniciar sessió",
                errorTitle: "Hem detectat un problema...",
                invalidToken: "L'enllaç ja no és vàlid, torna'l a demanar a de nou!",
            },
            forgotPassword: {
                title: "Recuperar contrasenya",
                mainTitle: "No et preocupis, ara t'ajudem",
                secondaryText: "Introdueix el correu que utilitzes Nadius",
                helperText: "Rebràs un correu amb les instruccions de recuperació en cas que hi hagi un compte associat",
                loginButton: "Rebre correu",
                loginLoading: "Enviant correu",
                email: "Correu electrònic per la recuperació",
                successTitle: "Correu enviat!",
                successSecondaryTitle: "Consulta el teu correu electrònic per restablir la contrasenya",
                understood: "Entesos",
                successHintText: "Si no el reps, consulta la carpeta de \"Spam\" o revisa que ho hagis introduït correctament."
            },
            register: {
                errorInData: "No s'ha pogut desar",
                errorInDataDesc: "Sembla que hi ha errors en les dades introduïdes",
                headerTitle: "Accés",
                nameTitle: "El teu nom",
                namePlaceholder: "Joan",
                emailTitle: "El teu correu electrònic",
                emailPlaceholder: "correu@pm.me",
                passwordTitle: "Contrasenya",
                passwordPlaceholder: "········",
                retypePasswordTitle: "Repeteix contrasenya",
                retypePasswordPlaceholder: "········",
                registerButton: "Registrar-se",
                title: "Registre",
                createUserDescription: ""
            },
            login: {
                title: "Accés",
                nameTitle: "El teu nom",
                namePlaceholder: "Joan",
                emailTitle: "El teu correu electrònic",
                emailPlaceholder: "correu@pm.me",
                passwordTitle: "Contrasenya",
                passwordPlaceholder: "········",
                retypePasswordTitle: "Repeteix contrasenya",
                retypePasswordPlaceholder: "········",
                loginLoading: "Iniciant sessió",
                loginButton: "Iniciar sessió",
                forgotPassword: "He oblidat la meva contrasenya"
            },
            waitValidation: {
                mainTitle: "Estem validant les dades introduïdes ...",
                verificationText: "Ens posarem en contacte personalment amb tu a través del següent correu electrònic:",
                secondaryText: "Si vols posar-te en contacte amb nosaltres, escriu-nos a hola@nadius.cat",
                okButton: "Entès",
                closeSession: "Tancar sessió"
            },
            verification: {
                welcomeTitle: "Tot ha anat sobre rodes",
                emailExplain: "Necessitem confirmar la seva adreça de correu, accedeix a l'enllaç que t'hem enviat a:",
                nextToValidationExplain: "Un cop validada, podràs iniciar la sessió i seguir amb el procés de registre.",
                okButton: "Entesos",
            },
            createProducerAccount: {
                mainHeader: "Hola",
                options: "Has parlat amb l'equip de Nadius i t'han proporcionat un codi d'accés?",  
                yes: "Si",
                no: "No",
                description: "{name} aquesta aplicació és només per a productors. Omple el següent formulari i ens posarem en contacte.",
                iWantToBe: "Sí, vull continuar el registre com a productor / a",
                iAccept: "Sí, accepto [la política de privacitat](https://productor.nadius.cat/ca/privacy) i els [termes i condicions del productor/a](https://productor.nadius.cat/ca/terms) ",
                createAccount: "Crear compte",
                errorInDataDesc: "Sembla que hi ha errors en les dades introduïdes",
                form: {
                    accessCode: "Codi d'acces",
                    enterCode: "Enviar codi",
                    website: "Pàgina Web",
                    websiteDesc: "L'enllaç a la seva pàgina web - deixa-ho buit si no teniu",
                    producerName: "Nom de la marca",
                    producerNameDesc: "El nom amb el qual comercialitza els seus productes",
                    producerNamePlaceholder: "Cal Nadius",
                    message: "Activitat i missatge",
                    messageDesc: "Explica'ns quina és la seva activitat i deixa'ns qualsevol comentari que vulgui enviar-",
                    messagePlaceholder: "Sóc apicultor. M'agradaria unir-me a Nadius per formar part de la revolució del model de consum!",
                }
            },
            index: {
                title: "Inici",
                hello: "Hola {name}",
                nameComodin: "Productor/a",
                text: "Aquesta app permet gestionar els teus productes a la plataforma de Nadius.",
                downloadText: "No ets un/a productor/a?",
                downloadButtonText: "Descarregat l'aplicació per a clients",
                textRegistered: "Per continuar amb el registre, accedeix al teu correu {email} i fes click al enllac adjunt, després inicia sessió.",
                downloadTextRegistered: "No ets un/a productor/a?",
                downloadButtonTextRegistered: "Descarregat l'aplicació per a clients",
                registerButton: "Registrar-se",
                loginButton: "Iniciar Sessió",
                registerAlert: {
                    title: "Encara no has acabat",
                    description: "No has acabat el registre de {email}, vols registrar un altre compte?",
                    confirmButton: "Sí, vull registrar una altra",
                    cancelButton: "Cancel·lar",
                }
            },
            nextSteps: {
                mainHeader: "El meu perfil de productor",
                firstStep: "Completar perfil",
                secondStep: "Verificació de Nadius",
                thirdStep: "Fet!",
                description: "Completa la següent informació per poder validar la seva inscripció a Nadius",
                companyForm: "Omplir el formulari d'empresa",
                kycForm: "Documents d'identificació fiscal",
                kycFormDisabled: "Es necessari omplir primer el formulari d'empresa",
                deliveryForm: "Establir les zones i els paràmetres d'enviament",
                publicProfile: "Completar el teu perfil públic",
                addProducts: "Afegeix com a mínim un producte",
                buttonText: "Omplir",
                addMore: "Afegir un altre",
                add: "Afegir",
                submit: "Enviar per revisió",
                startText: "Començar a vendre",
                birthplace: "Lloc de neixament",

                validating: "Estem validant la informació, t'informarem de qualsevol canvi!",

                onSumbitInvalidStatus: "No cal tornar a validar les dades perquè ja han estat validats.",
                onGoLiveInvalidStatus: "Encara no pots començar a vendre amb Nadius.",
                errorDeliveryPending: "Cal la informació de la política d'enviament perquè l'equip de Nadius pugui fer la validació.",
                errorCompanyPending: "Cal la informació de l'empresa perquè l'equip de Nadius pugui fer la validació.",
                errorProductPending: "Cal la informació de com a mínim 1 producte perquè l'equip de Nadius pugui fer la validació.",
                errorProfilePending: "Cal la informació del perfil públic perquè l'equip de Nadius pugui fer la validació.",
            },
            welcomeProcess: { 
                title: "Benvingut al procés de selecció de Nadius",
                description: "Ens prenem molt seriosament la selecció de productors per verificar que els productes siguin de qualitat i oferir una bona experiència de compra per als consumidors.",
                subtitle: "És molt senzill i només et portarà uns minuts",
                step1Title: "1. Explica'ns qui sou",
                step1Description: "Completa el formulari perquè puguem conèixer-te",
                step2Title: "2. Validem la informació",
                step2Description: "Revisarem la vostra informació i us ajudarem en qualsevol dubte que pugueu tenir.",
                step3Title: "3. Crea la botiga en línia",
                step3Description: "Junts crearem la vostra botiga digital i definirem el catàleg de productes.",
                step4Title: "4. Comença a vendre",
                step4Description: "Un cop tot estigui enllestit, només quedarà començar a vendre els teus productes!",
                startButton: "¿COMENCEM?",
            },
            language: {
                button: "Següent",
                welcome: "Benvingut a Nadius",
                first: "Primer de tot, permeta'ns personalitzar-te la teva experiència",
                language: "Quin idioma vols utilitzar en la plataforma?",
                catalan: "Català",
                spanish: "Castellano"
            }
        },
        order: {
            details: {
                state: "Estat",
                update: "Actualització",

                pendent: "Pendent",
                preparing: "Preparació",
                transit: "Trànsit",
                delivered: "Lliurat",
                created: "Creació",
                cancelled: "Cancel·lada",
                dispute: "Incidència",
                pickupReady: "Llest per recollir",
                pickupDone: "Recollida",
                pickupHours: "Hores",
                pickupDirection: "Direcció",
                pickupInfo: "Informació de recollida",
                pickupPrice: "Recollida",
                userData: "Dades de contacte",

                cancelChange: "Cancel·lar",
                changeButton: "Actualitzar",
                sendmessage: "Enviar missatge",
                sendingMessage: "Enviant ...",
                message: "Missatge",
                cancelOrder: "cancel·lar",
                respondClient: "Respondre",
                sendAgainClient: "Enviar un altre missatge",
                contactDescription: "El client rebrà un avís d'aquest missatge",
                max300: "Màxim 300 caràcters",

                yourMessage: "Tu",
                clientMessage: "Client",

                noMessages: "No hi ha cap missatge",
                messagesTitle: "Missatges",

                cancelOrder: "Cancel·lar-la",
                cancelType: "Selecciona la raó",

                cancelIncorrectProducts: "Producte/s incorrecte/s",
                cancelNoStock: "No hi ha estoc",
                cancelOther: "Una altra",

                cancelCharge: "Selecciona la raó",
                cancelOtherTitle: "Dóna'ns més detalls",
                cancelPlaceholder: "Perquè ...",
                cancelReason: "Raó",

                cancelDisclaimer: "Ja s'ha processat el pagament, per aquest motiu se li reemborsarà al client l'import complet i a tu se't descomptarà",
                cancelDisclaimerContinue: ", que és exactament el que ens costa processar el pagament.",
                cancelContact: "En cas de ser un problema nostre, indica-ho i ens posarem en contacte amb tu.",

                controlPanel: "Panell de control",
                disputeTitle: "Incidència",
                disputeSolvedStatus: "Resolta",
                disputeSolvedPending: "Pendent",
                updateState: "Actualitza l'estat",
                disputeResolved: "Marca com a resolta",
                disputeResolvedDescription: "Es marcarà com resolta per la teva part i si el client no creu el contrari, es tancarà.",
                acceptOrder: "Acceptar comanda",
                markAsDelivered: "Marca com a lliurat",
                editSendingData: "Modificar enviament",
                acceptOrderDescription: "Una vegada acceptada, s'enviarà un missatge al client avisant d'aquest canvi d'estat",

                sendingData: "Dades d'enviament",
                summaryTitle: "Resum",
                units: "Unitats",
                name: "Nom",
                total: "Total",
                sendingPrice: "Enviament",
                totalPrice: "Preu total",
                free: "Gratuït",

                direction: "Direcció",
                orderNumber: "Identificador de comanda",
                orderAggregatedNumber: "Identificador de comanda conjunt",

                notArrived: "No ha arribat el producte",
                notCorresponding: "El que he rebut no correspon amb el que vaig demanar",
                badStatus: "Un o múltiples productes no estan en bon estat",
                other: "Un altre",
                typeDispute: "Tipus",
                disputeMessage: "Missatge del client",
                sending: "Enviant ...",
                loading: "Carregant ...",

                contactClient: "Contactar client",
                contactClientDescription: "El missatge s'enviés a través de Nadius",
                markAsSended: "Marca com enviat",
                markAsReadyToPickUp: "Ja està llesta per recollir",
                markAsReadyToPickUpDescription: "S'enviarà un missatge al client avisant d'aquest canvi d'estat",
                markAsDelivered: "Marcar com a entregat",
                markAsDeliveredDescription: "En cas de no haver-se entregat, el client podrà crear una incidència i es pararà temporalment el processament de la comanda fins que es resolgui.",
                markAsPickup: "Marcar com a recollida",
                markAsPickupDescription: "En cas de no haver-se recollit, el client podrà crear una incidència i es pararà temporalment el processament de la comanda fins que es resolgui.",
                canContact: "Em pots contactar ...",
                messageToClient: "Missatge al client",
                messageToClientHelp: "Explica com el client pot fer el seguiment. (màx. 150)",
                name: "Nom",
                nameOfCompany: "Nom de la companyia que farà l'enviament",
                otherCompany: "Una altra",
                company: "Empresa",
                extern: "Externa",
                sendOrder: "Enviar comanda",
                oneDay: "24h",
                oneTwoDays: "24/48h",
                threeToFour: "48/72h",
                oneWeek: "1 setmana",
                moreThanOneWeek: "Més d'una setmana",
                contactPhone: "Telèfon de contacte",
                method: "Mètode",
                personally: "Personalment",
                pickerPlaceholder: "Selecciona una opció",
                sendingTime: "Temps d'enviament",
                idTracking: "ID de seguiment",
                idTrackingHelp: "Identificador proporcionat per la companyia",
                deliveryDay: "Dia i horari de repartiment",
                ownMessage: "Missatge teu",
                selectSendingMethod: "Selecciona si l'enviament el féu vosaltres o una empresa externa.",
            },
            list: {
                headerBackTitle: "Resum",
                disputes: "Incidències",
                pending: "Pendents",
                preparing: "Preparant",
                transit: "En trànsit",
                delivered: "Entregades",
                title: "Totes les comandes",
                Canceled: "Cancel·lades",

                quantity: "Quantitat",
                total: "Total",

                disputesTitle: "Incidències",
                pendingTitle: "Pendents",
                preparingTitle: "En preparació",
                transitTitle: "En trànsit",
                deliveredTitle: "Entregades",
                canceledTitle: "Cancel·lades",
                emptyOrders: "Encara no has rebut cap comanda"
            }
        },
        errorsDescription: {
            emptyField: "El camp no pot estar buit",
            wrongDecimalFormat: "El camp no està en el format adequat: 00.00",
            invalidCharacters: "El camp conté caràcters invàlids",
            invalidValue: "El camp conté un valor invàlid",
            enumMismatch: "Has d'escollir una opció vàlida",
            noMatch: "El valor esperat no coincideix amb l'introduït"
        },
        producerOptions: {
            profile: "El meu perfil",
            products: "Els meus productes",
            orders: "Comandes",
            groups: "Grups",
            finance: "Finances",
        },
        finances: {
            kycDone: {
                headerTitle: "En procés",
                mainTitle: "Estem validant les dades introduïdes ...",
                verificationText: "En cas de tenir algun problema ens posarem en contacte amb tu.",
                secondaryText: "Si vols posar-te en contacte amb nosaltres, escriu-nos a hola@nadius.cat",
                okButton: "Entès"
            },
            kycForm: {
                selectTitle: "Document a enviar",
                store: "Guardar",
                shareholderDeclaration: "Declaració d'accionistes",
                shareholderDeclarationDesc: "Declarar a tots els accionistes / administradors amb més o igual 25% del capital o drets de vot de l'empresa.",
                registerProof: "Prova de registre",
                statutes: "Estatuts",
                pasaport: "Passaport",
                drivingLicence: "Carnet de conduir",
                residencePermit: "Permís de residència",
                selectTypeOfDocument: "Selecciona una opció",
                labelRegister: "Extracte del Registre Mercantil Central",
                labelRegistrators: "Extracte de Registradors d'Espanya",
                labelModel600: "Model 600",
                lessThan3Months: "El document han de tenir una data menor a 3 mesos",
                lessThan3MonthsResolution: "El document de la Resolució ha de tenir una data menor a 3 mesos",
                labelRNA: "Prova registre en el RNA (Registre Nacional d'Associacions)",
                labelRegisterSoletrader: "Resolució alta d'autònom",
                labelFiscalCard: "Targeta d'identificació fiscal",
                addAnotherUBO: "Afegir un altre accionista",
                delete: "Eliminar",
                confirm: "Confirma l'acció",
                confirmDesc: "Una vegada eliminat, es perdran les dades introduïdes",
                Birthplace: "Lloc de naixement",
                nameAndSurnames: "Nom i cognoms",
                namePlaceholder: "Ex. Josep",
                surnamePlaceholder: "Ex. Vila",
                street: "Carrer",
                streetPlaceholder: "Ex. carrer de la Vila, 23, 1a",
                birthday: "Data de naixement",
                nationality: "Nacionalitat",
                city: "Ciutat",
                country: "País",
                bannerDescription: "En cas de tenir qualsevol problema amb el formulari o prefereixes enviar-lo per correu, posa't en contacte amb nosaltres.",
                identityVerification: "Verificació d'identitat",
                unableFindDocument: "El fitxer no existeix",
                missingDocuments: "Falten documents. \n Revisa que s'hagin guardat correctament",
                reviewTab: "revisar",
                invalidDate: "La data seleccionada no és correcta",
                cancelAlert: "Cancel·lar",
                disclaimer: "Totes les dades d'aquest formulari són gestionats per l'entitat encarregada de processar els pagaments i per prevenir frau. En cap cas es faran servir amb cap altra finalitat.",
                identityProof: "Representant legal",
                identityProofDescription: "Es necessita un document que verifiqui la identitat del representant legal",
                possibleDocumentsLimitation: "Només són vàlids els documents amb format pdf, .jpeg, .jpg, .gif i .png."
            },
            TRANSFER: "Transferència",
            PAYIN: "Ingrés",
            PAYOUT: "Transferència al teu compte bancari",
            at: "A les",
            order: "Comanda",
            transferButton: "Transferir",
            modalTitle: "Realitzar transferència",
            available: "Disponible:",
            continue: "Continuar",
            yesterday: "Ahir",
            today: "Avui",
            Payin: "Ingrés",
            endReached: "No hi han més",
            Payout: "Transferència al teu banc",
            maxTransactions: "Només pots veure les últimes {maxTransactions} transaccions.",
            noTransfers: "No hi ha cap transacció",
            yourSalary: "El teu balanç",
            bannerKYC: "Per poder transferir al teu compte bancari, necessitem que proporcions certa informació",
            bannerValidatingKYC: "Els documents estan sent validats. T'avisarem tan bon punt hàgem acabat.",
            fillForm: "Omplir formulari",
            transfer: {
                amountExceed: "El valor excedeix la quantitat disponible",
                payoutDelay: "La transferència pot trigar entre 1 a 7 dies a arribar"
            }
        },
        account: {
            security: {
                alertTitle: "Canviar la contrasenya",
                alertDescription: "Rebràs un correu per poder efectuar el canvi",
                cancel: "Cancel·lar",
                ok: "Canviar",
                headerTitle: "Hola",
                titlePassword: "Contrasenya",
                changePassword: "Canviar-la",
                loadingModalText: "Enviant correu",
                successModalText: "Correu enviat"
            },
            userInfo: {
                noData: "Sense dades",
                headerTitle: "Dades d'usuari"
            },
            userInfoEdit: {
                nameAndSurnames: "Nom i cognoms",
                name: "Nom",
                surname: "Cognoms",
                email: "Correu electrònic",
                emailTitle: "Correu",
                phone: "Telèfon",
            },
            language: {
                language: "Llenguatge"
            }
        },
        choose: {
            title: "Configuració",
            alertTitle: "Vols tancar la sessió?",
            alertDescription: "Hauràs de tornar a introduir de nou les credencials",
            alertCancel: "Cancel·lar",
            alertConfirm: "Tancar sessió",
            producerProfile: "Perfil públic",
            sellingZones: "Zones d'enviament",
            pickupPoints: "Punt de recollida",
            companyForm: "Dades fiscals",
            userSettings: "El teu usuari de Nadius",
            supportContact: "Contacte amb suport",
            closeSession: "Tancar sessió",
            closing: "Tancant",
        },
        unit: {
            u: "ud",
            pkg: "pack",
            mg: "mg",
            g: "g",
            kg: "kg",
            ml: "ml",
            cl: "cl",
            l: "l"
        },
        productForm: {
            classification: "Classificació",
            languagesDesc: "Prem el botó 'Llenguatges' per afegir o eliminar-ne",
            headerTitleNew: "Nou producte",
            kg: "Quilogram",
            g: "Gram",
            mg: "mil·ligram",
            l: "Litre",
            ml: "mil·lilitre",
            cl: "centilitre",
            u: "Unitat",
            name: "Nom del producte",
            nameDesc: "El nom que identifica el producte",
            namePlaceholder: {
                es: "Formatge de cabra (250g)",
                ca: "Formatge de cabra (250g)",
                en: "Goat cheese (250g)",
            },
            defaultCategoryPlaceholder: "Selecciona una categoria",
            defaultSubcategoryPlaceholder: "Selecciona una subcategoria",
            description: "Descripció del producte",
            descriptionDesc: "Una explicació del producte, explica als consumidors que es tracta, com ho elabores, que formes hi ha de consumir-ho, com s'ha d'emmagatzemar ...",
            descriptionPlaceholder: {
                ca: "Elaborem el formatge de cabra ...",
                es: "Elaborem el formatge de cabra ...",
                en: "We elaborate the goat cheese ...",
            },
            ingredientsOptions: {
                image: "Añadir como imagen",
                text: "Añadir como texto ",
                noapply: "Marcar como no requerido",
            },
            imageShouldContainIngredientsDisclaimer: "En cas que el producte ho requereixi, afegeix una imatge amb la llista d'ingredients.",
            productManagment: "Gestió",
            priceType: "Tipus de preu",
            priceSection: "Preu i format",
            nameDescSection: "Nom i descripció",
            price: "Preu total",
            priceDefault: "Preu previ",
            priceOnSale: "Preu oferta",
            priceOnSaleInfo: "El preu final del producte en oferta",
            priceTypeLabelNoSale: "Sense oferta",
            priceTypeLabelOnSale: "En oferta",
            amountSection: "Quantitat per unitat",
            amountType: "Tipus de quantitat",
            amountFrom: "Des de",
            amountMax: "Fins",
            amountUnit: "Unitat",
            amountFixed: "Quantitat exacta",
            amountRange: "Quantitat variable",

            weightSection: "Pes total",
            weight: "Pes total del producte",
            weightUnit: "Unitat",
            weight: "Pes total",
            weightDesc: "Pes del producte i l'embolcall. Aquest valor s'utilitza per calcular i optimizar el cost d'enviament.",

            priceDescWithSale: "El preu anterior per unitat de format",
            priceDesc: "El preu final per unitat de format",
            priceUnit: "Format",
            packUnits: "Unitats en el pack",
            packUnitsInfo: "Quantes unitats porta un paquet",
            amount: "Quantitat",
            amountDesc: "La quantitat neta que inclou 1 unitat",
            labels: "Etiquetes",
            labelsHint: "Premi el botó \"Afegir\" per afegir atributs al producte",
            allergens: "Al·lèrgens",
            allergensHint: "Premi el botó \"Afegir\" per afegir al·lèrgens al producte",
            stock: "Quantitat en estoc",
            stockDesc: "Quants productes pots subministrar en total.",
            category: "Categoria de producte",
            subcategory: "Subcategoria de producte",
            categoryDesc: "Pots suggerir-nos noves categories, envia'ns un correu!",
            images: "Imatges",
            imagesHint: "Premi el botó \"Afegir\" per afegir imatges del producte",
            disableButton: "Deshabilitar producte",
            ownMade: "Sóc el productor del producte",
            enableButton: "Habilitar producte",
            deleteButton: "Eliminar producte",
            errorsDescription: {
                imageNumberExceded: "Només un màxim de 5 imatges poden ser afegides.",
                imageMinRequired: "Has d'afegir mínim 1 imatge del producte",
                invalidTags: "Les etiquetes seleccionades no són vàlides",
                invalidCategory: "La categoria seleccionada no són vàlida",
                invalidSubcategory: "La subcategoria seleccionada no són vàlida",
                invalidImage: "Si us plau, selecciona una altra imatge",
                priceSaleMustBeLowerThanPrice: "El preu en oferta ha de ser menor al preu anterior",
                priceMustBeHigherThan0: "El preu ha de ser major a 0",
                packUnitsMustBeHigherThan1: "Les unitats en el pack han de ser major a 1",
                amountMustBeHigherThan0: "La quantitat ha de ser major a 0",
                amountMaxMustBeHigherThanAmount: "La quantitat màxima ha de ser major a la quantitat mínima",
            },
            authError: "Operació no autoritzada. \n \n Contacta amb nosaltres si es tracta d'un error.",
            errorInDataDesc: "Sembla que hi ha errors en les dades introduïdes",
            info: {
                disableButton: "Quan el producte està deshabilitat, cap usuari pot veure-ho. Podrà la a habilitar quan vulgui.",
                deleteButton: "Quan el producte és eliminat, deixa d'estar disponible. Aquesta operació no pot desfer-se.",
                ownMadeTrue: "La selecció actual indica que vostè produeix i comercialitza aquest producte.",
                ownMadeFalse: "La selecció actual indica que vostè NO produeix, però comercialitza aquest producte.",
            },
            deleteProductAlertTitle: "Eliminar Producte",
            deleteProductAlertDesc: "S\'eliminarà definitivament, no podràs desfer aquesta operació.",

        },
        profile: {
            about: {
                disclaimerInformation: "La informació present en aquesta vista ha estat afegida per"
            },
            index: {
                headerBackTitle: "Configuració",
                viewProfile: "Veure perfil",
                header: "El teu perfil públic",
                profileHeaderTitle: "Capçalera",
                pageDescription: "Així és com els clients veuen la capçalera, la targeta i el teu perfil.",
                cardInResults: "Targeta",
                modifyData: "Modificar les teves dades"
            },
            form: {
                generalTitle: "Informació general",
                locationTitle: "Ubicació",
                contactTitle: "Contacte",
                profileTitle: "Perfil",
                mainHelper: "Selecciona l'apartat a modificar",
                title: "Edita perfil",
                name: "Nom",
                city: "Ciutat, Regió",
                postalCode: "Codi postal",
                street: "Direcció",
                streetPlaceHolder: "Ex. carrer de la Via, 23, 1A",
                nameDesc: "El nom del productor d'aquest perfil",
                namePlaceholder: "Productor de Formatges",
                slogan: "Eslògan o subtítol",
                sloganDesc: "Max. 100 caràcters",
                sloganPlaceholder: "Fent els millors formatges des de 1970",
                description: "Contingut",
                descriptionDesc: "Max. 1000 caràcters",
                descriptionPlaceholder: "Explica als consumidors qui sou, que feu, quina és la vostra història ...",
                email: "Correu electrònic",
                emailDesc: "Correu electrònic amb el qual vols que et contactin els clients",
                telephone: "Telèfon",
                telephoneDesc: "Telèfon de contacte per als clients",
                website: "Pàgina web",
                websiteDesc: "Pàgina web pròpia",
                profileImage: "Imatge de perfil",
                selectButton: "Seleccioneu imatge",
                coverImage: "Portada",
                galery: "Galeria",
                images: "Imatges",
                imagesHint: "Premi el botó \"Afegir\" per afegir imatges",
                mapHelp: "Prem al mapa per modificar la ubicació",
                errorsDescription: {
                    imageNumberExceded: "Només un màxim de 5 imatges poden ser afegides.",
                    imageMinRequired: "Has d'afegir mínim 1 imatge",
                    invalidImage: "Si us plau, selecciona una altra imatge",
                    invalidCoordinates: "Si us plau, introdueixi unes coordenades correctes"
                },
                errorInData: "No s'ha pogut desar",
                errorInDataDesc: "Sembla que hi ha errors en les dades introduïdes",
                dataUploaded: "Dades rebudes correctament",
                dataUploadedMessage: "Aviat seran revisats i acceptats",
            },
            map: {
                mapInfoHelper: "Mantingues pressionat per seleccionar la ubicació.",
                producerLocation: "On estàs?",
                setMarker: "Mantingues pressionat per seleccionar una ubicació",
                dragMarker: "Mantingues pressionat o arrossega el marcador per canviar la ubicació",
                saveLocation: "Seleccioneu ubicació"
            }
        },
        zones: {
            details: {

                zoneNotSelected: "Has de seleccionar mínim una zona",

                province: "Provincia",

                whereDoesItApplies: "Ubicacions que aplica",
                selectOnePossible: "Selecciona una de las següents opcions",

                actionsTitle: "Accions",
                deleteButton: "Eliminar",
                deleteDesc: "Quan s'elimini, pot ser que durant un període limitat de temps encara tingui efecte.",
                
                nameTitle: "Nom",
                name: "Nom de la Zona",
                namePlaceHolder: "Zona de Barcelona",
                withoutName: "Zona sense nom",
                addSuplement: "Afegir suplement per pes",

                back: "Zones",
                zoneNotSelected: "Has de seleccionar mínim una zona",
                province: "Província",

                errorInData: "No s'ha pogut desar",
                errorInDataDesc: "Sembla que hi ha errors en les dades introduïdes",
                headerTitle: "Preu i zones d'enviament",
                sellZonesTitle: "Zones d'enviament",
                deliveryPrice: "Preu d'enviament",
                deliveryPriceDesc: "El preu d'enviament per comanda",

                alwaysFree: "Enviament gratuït",
                notAlwaysFree: "A partir de cert preu",
                neverFree: "Mai gratuït",

                actionsTitle: "Accions",
                deleteButton: "Eliminar",
                deleteDesc: "Quan s'elimini, pot ser que durant un període limitat de temps encara tingui efecte.",

                alwaysFree: "Sempre gratuït",
                notAlwaysFree: "A partir d'un cert preu",
                neverFree: "Mai gratuït",

                freeDeliveryTitle: "Enviament gratuït",
                freeDeliverySelect: "Modalitat",
                freeDelivery: "Preu mínim per enviament gratuït",
                freeDeliveryDesc: "Preu mínim de la comanda perquè pugui gaudir d'enviament gratuït",

                minOrder: "Comanda mínima",
                minOrderSelect: "Modalitat",
                noMinOrder: "Sense mínim preu per comanda",
                withMinOrder: "Amb mínim preu per comanda",
                minOrderDesc: "Preu mínim que ha de tenir la comanda",

                deliveryMethods: "Mètode d'enviament",
                deliveryMethodSelect: "Modalidat",
                deliveryMethodDefault: "Seleccionar una opció",
                deliveryMethodPersonally: "Personalment",
                deliveryMethodExternal: "Empresa externa",

                nameTitle: "Nom",
                name: "Nom de la Zona",
                namePlaceHolder: "Zona de Barcelona",
                withoutName: "Zona sense nom",
                addSuplement: "Afegir suplement per pes",

                whereDoesItApplies: "Ubicacions que aplica",
                selectOnePossible: "Selecciona una de les següents opcions",
                whereDoesItAppliesDesc: "Seleccionant 'Personalitzat', podràs escollir en detall a nivell de població fins província.",
                personalizedButton: "Defineix la zona",
                personalized: "Personalitzat",

                price: "Preu",
                priceDisabled: "El preu no te cap efecte donada la selecció de del enviament gratuït",
                suplementEvery: "Per cada",
                priceWeight: "Suplement de",
                addSuplement: "Afegir suplement per pes",
                suplementDesc: "Es important definir correctament el pes per cada producte per a que funcioni correctament.",


                minOrder: "Comanda mínima",
                minOrderSelect: "Modalidat",
                noMinOrder: "Sense comanda mínima",
                withMinOrder: "Amb comanda mínima",
                minOrderDesc: "Preu",

                deliveryMethods: "Mètode d'enviament",
                deliveryMethodSelect: "Modalitat",
                deliveryMethodDefault: "Seleccionar una opció",
                deliveryMethodPersonally: "Personalment",
                deliveryMethodExternal: "Empresa externa",

                sellZonesDesc: "Selecciona a continuació les zones on pots enviar els teus productes",
                timeTitle: "Temps d'enviament",

                time: {
                    selectOne: "Selecciona una opció",
                    oneDay: "1 dia",
                    oneTwoDays: "1-2 dies",
                    threeToFour: "3-4 dies",
                    oneWeek: "1 setmana",
                },
                daysTitle: "Selecciona els dies que realitzes els enviaments",
                deliveryDaysHelp: "Si no tens un horari definit, no cal seleccionar cap dia",
                days: {
                    monday: "Dilluns",
                    tuesday: "Dimarts",
                    wednesday: "Dimecres",
                    thursday: "Dijous",
                    friday: "Divendres",
                    saturday: "Dissabte",
                    sunday: "Diumenge",
                },
                shortDays: {
                    monday: "Dl",
                    tuesday: "Dt",
                    wednesday: "Dc",
                    thursday: "Dj",
                    friday: "Dv",
                    saturday: "Ds",
                    sunday: "Dg",
                },
                errors: {
                    freeFromBiggerThanMinOrder: "Donada la selecció actual, no pots posar l'enviament gratuït igual o menor al preu mínim per comanda."
                }
            },
            list: {

                back: 'Enrere',

                maxDesc: "de 10 disponibles",
                extern: "Extern",
                deliveryIn: "Enviament en",

                noneYet: "Encara no tens cap zona definida",
                noneYetDesc: "Prem en el botó 'Afegir una zona' per crear-ne",

                locationNotYetTitle: "Encara no has establert la teva ubicació",
                locationNotYetDesc: "Accedeix a l'apartat de 'Perfil públic', prem modificar i estableix l'ubicació",
                navigate: "Accedir a Perfil Públic",

                price: "Preu",
                suplement: "Suplement",
                freeFrom: "Gratuït desde",
                minOrder: "Comanda mínima de",
                alwaysFree: "Enviament gratuït",
                noFreeFrom: "Mai gratuït",
                headerTitle: "Zones d'entrega",
                noZones: "No hi han zones definides",
                addNew: "Afegir una zona",
                pickupTitle: "Punt de recollida",
            },
        },
        pickup: {
            back: "Enrere",
            name: "Nom del punt",
            nameTitle: "Nom",
            namePlaceHolder: "Les nostres instal·lacions",
            nameHelp: "El nom que veurán els clients com punt de recollida",
            headerTitle: "Punt de recollida",
            activate: "Habilitar punt de recollida",
            activateTitle: "Habilitar",
            possibleDays: "Selecciona els dies que es pot recollir",
            timeTitle: "Dies i horaris",
            store: "Desar"
        },
        profileSections: {
            name: "Nom",
            slogan: "Eslògan",
            description: "Qui som?",
            images: "Imatges",
            contact: "Contacte"
        },
        profileInfos: {
            name: "Nom de la marca",
            slogan: "Eslògan de la marca",
            description: "Qui sou? Que feu? Quina és la vostra història?...",
            images: "Prem el botó \"Afegir\" per afegir imatges del que feu, on treballeu, com ho feu ..."
        },
        locale: {
            es: "Espanyol",
            ca: "Català",
            en: "Anglès",
            gl: "Gallego",
            eu: "Euskera",
            ast: "Asturià",
            oc: "Occità",
            an: "Aragonès"
        },
        sended: "Enviat!",
        success: "Fet!",
        loading: "Carregant ...",
        sellZones: {
            headerTitle: "Zones de venda",
            salePlaces: "Selecciona les teves ubicacions de venda:",
            saleZonesUpdated: "Les zones de venda s'han actualitzat correctament",
            saleZonesError: "Error en actualitzar les zones de venda",
            saleZonesErrorDesc: "Prova-més tard, si l'error persisteix, truqueu-nos.",
        },
        message_uploadOperationTakingLong: "La operación esta tardando demasiado, pulse el boton para cancelarla",
        message_uploadOperationTakingLong: "L'operació aquesta trigant massa, premi el botó per cancel·lar",
        companyForm: {
            common: {
                Direction: "Nom del carrer i número",
                backButton: 'Enrere',
                continueButton: "Continuar",
                header: "Dades de l'empresa",
                errorInData: "No s'ha pogut desar",
                success: "Les dades de la companyia s'han desat correctament"
            },
            companyForm: {
                generalInfo: "Informació general",
                legalRepresentative: "Representat legal",
                finantialData: "Dades financeres"
            },
            fiscal: {
                header: "Dades fiscals",
                readOnly: "Si vols modificar alguna d'aquestes dades, contacta amb nosaltres a hola@nadius.cat",
                error: "No s'han rebut les dades correctament"
            },
            general: {
                Identification: "Identificació",
                Name: "Nom de l'empresa",
                NIF: "Número d'identificació fiscal - NIF",
                LegalPersonType: "Forma jurídica",
                Contact: "Contacte de l'empresa",
                Email: "Correu electrònic",
                PhoneNumber: "Número de telèfon",
                HeadquartersAddress: "Adreça física",
                legalPersonType: {
                    business: "Empresa",
                    organization: "Organització",
                    soletrader: "Autònom"
                },
                NameInfo: "Nom fiscal de l'empresa.",
                NIFInfo: "Número d'identificació fiscal.",
                EmailInfo: "S'utilitzarà per a comunicacions legals o organitzatives.",
                HeadquartersStreetInfo: "Direcció física legal de l'empresa."
            },
            finantial: {
                FinantialData: "Dades Bancàries",
                OwnerName: "Titular del compte",
                IBAN: "Número de compte - IBAN",
                OwnerAddress: "Adreça física",
                ownerAddressHeadquartersChecked: "Utilitzar l'adreça física de l'empresa",
                ownerAddressLegalRepresentativeChecked: "Utilitzar l'adreça física del representant legal",
                nameInfo: "Nom del titular del compte on es rebran els ingressos",
                ibanInfo: "IBAN del compte on es rebran els ingressos",
                direction: "Direcció associada al compte bancari"
            },
            legalRepresentative: {
                header: "Representant Legal",
                name: "Nom",
                surnames: "Cognoms",
                Birthday: "Data de naixement",
                Email: "Correu electrònic",
                Nationality: "Nacionalitat",
                CountryOfResidence: "País de residència",
                LegalRepresentativeAddress: "Adreça física",
                legalRepresentativeChecked: "Utilitzar l'adreça física de l'empresa",
                mailInfo: "Només s'utilitzarà com a prova d'identitat de l'empresa.",
                addressInfo: "Direcció del representant legal."
            }
        },

        root: {
            screens: {
                Welcome: 'Inici',
                AccessLogin: 'Inciar sessió',
                AccessRegister: 'Registre',
                AccessForgot: 'Recuperació de contrasenya',
                AccessVerification: "Verificació",
                PrivacyScreen: "Política de privacitat",
                AccessEmailVerification: "Confirmació de correu",
                AccessResetPassword: "Restablir contrasenya",
                CreateProducerAccount: "Sol·licitar accés",
                WaitingValidation: "Esperant validació",
                Comandes: "Comandes",
                Orders: "Comandes",
                NextSteps: "Procés de registre",
                CompanyForm: "Formulari d'Empresa",
                KYCForm: "Documents de verificació",
                KYCSubmit: "En procés de verificació",
                Productes: "Productes",
                ProducerProfile: "Perfil de productor",
                Business: "Dedes fiscals",
                ProfileForm: "Perfil de productor",
                Products: "Productes",
                ProductForm: "Formulari de producte"
            },
            firstTab: "Comandes",
            secondTab: "Productes",
            thirdTab: "Finances",
            profileTab: "Perfil productor",
            productsTab: "Catàleg de productes",
            business: "Dades fiscals",
            lastTab: "Paràmetres",
            noInternet: "Estàs sense connexió a internet"
        }
    },
    es: {
        ...translations("es"),
        products: {
            title: "Tus productos",
            disabled: "Inactivo",
            addButton: "Añadir nuevo",
        },
        update: {
            mainTitle: "Actualizando",
            downloading: "Descargando",
            installing: "Instalando...",
            restart: "Reiniciando..."
        },
        orders: {
            tableTitle: 'Pedidos',
            tableOrder: 'Pedido',
            tableDestination: 'Destino',
            tableTotal: 'Total',
            tableStatus: 'Estado',

            notArrived: "No ha llegado",
            unwantedProducts: "Productos no correponden",
            badStatus: "En mal estado",
            other: "Otros",
            seeAll: "Ver todos los pedidos",
            summary: "Resumen",
            pending: "Pendientes",
            preparing: "Preparándose",
            transit: "En tránsito",

            preparingReady: "Para preparar",
            consolidationReady: "En centro logístico",

            products: "Productos",
            total: "Total",
            pickup: "Recogida",

            subTitlePreFix: "Tienes",
            subTitleSufixDisputes: "incidencias",
            subTitleSufixDispute: "incidencia",
            subTitleNewOrder: "pedido nuevo",
            subTitleNewOrders: "pedidos nuevos",
            subTitleLeft: "Quedan",
            subTitleToDeliver: "para entregar",
            everythingOk: "Todo en orden",

            seeMore: "Ver más",
            disputesTitle: "Incidencias",
            pendingTitle: "Pendientes de respuesta",
            preparingTitle: "En preparación",
            transitTitle: "En tránsito o listos para recoger",
            noneNow: "No hay actualmente"
        },
        button: {
            add: "Añadir",
            noneLeft: "Agotado",
            save: "Guardar",
            done: "Hecho",
            seeMore: "Ver más",
            cancel: "Cancelar",
            delete: "Eliminar",
            all: "Todo",
            edit: "Modificar",
            addOrRemoveTranslation: "Lenguajes",
            headerTruncatedBack: "Atrás"
        },
        access: {
            resetPassword: {
                title: "Restablecer contraseña",
                new: "Nueva contraseña",
                newPlaceholder: "········",
                submitButton: "Cambiarla",
                repeat: "Vuelve a escribirla",
                retypePasswordPlaceholder: "········",

                successTitle: "La contraseña se ha modificado correctamente!",
                successDescription: "Ya puedes iniciar sesión a la plataforma",

                goToLogin: "Iniciar sesión",
                errorTitle: "Hemos detectado un problema...",
                invalidToken: "En enlace ya no es válido, vuélvelo a pedir de nuevo!",
            },
            emailVerification: {
                invalidToken: "No hemos podido confirmar tu cuenta a partir del enlace, lo has abierto correctamente?",
                errorTitle: "Hemos detectado un problema...",
                title: "Validando tu cuenta...",
                successTitle: "Tu cuenta se ha verificado correctamente!",
                successDescription: "Ya puedes iniciar sesión en la plataforma.",
                unexisting: "El enlace no es válido",
                goToLogin: "Iniciar sesión"
            },
            forgotPassword: {
                title: "Recuperar contraseña",
                mainTitle: "No te preocupes, ahora te ayudamos",
                secondaryText: "Introduce el correo que usas en Nadius",
                helperText: "Recibirás un correo con las instrucciones de recuperación en caso que exista una cuenta asociada",
                loginButton: "Recibir correo",
                loginLoading: "Enviando correo",
                email: "Correo electrónico para la recuperación",
                successTitle: "Correo enviado!",
                successSecondaryTitle: "Consulta tu correo electrónico para restablecer la contraseña",
                understood: "Entendido",
                successHintText: "Si no lo recibes, consulta la carpeta de Spam o revisa que lo hayas introducido correctamente."
            },
            register: {
                errorInData: "No se ha podido guardar",
                errorInDataDesc: "Parece que hay errores en los datos introducidos",
                headerTitle: "Acceso",
                nameTitle: "Tu nombre",
                namePlaceholder: "Juan",
                emailTitle: "Tu correo electrónico",
                emailPlaceholder: "correu@pm.me",
                passwordTitle: "Contraseña",
                passwordPlaceholder: "········",
                retypePasswordTitle: "Repite contraseña",
                retypePasswordPlaceholder: "········",
                registerButton: "Registrarse",
                title: "Registro",
                createUserDescription: ""
            },
            login: {
                title: "Acceso",
                nameTitle: "Tu nombre",
                namePlaceholder: "Juan",
                emailTitle: "Tu correo electrónico",
                emailPlaceholder: "correu@pm.me",
                passwordTitle: "Contraseña",
                passwordPlaceholder: "········",
                retypePasswordTitle: "Repite contraseña",
                retypePasswordPlaceholder: "········",
                loginLoading: "Iniciando sesión",
                loginButton: "Iniciar sesión",
                forgotPassword: "He olvidado mi contraseña"
            },
            waitValidation: {
                mainTitle: "Estamos validando los datos introducidos...",
                verificationText: "Nos pondremos en contacto personalmente contigo a través del siguiente correo electrónico:",
                secondaryText: "Si quieres ponerte en contacto con nostros, escríbenos a hola@nadius.cat",
                okButton: "Entendido",
                closeSession: "Cerrar sesión"
            },
            verification: {
                welcomeTitle: "Todo ha ido sobre ruedas",
                emailExplain: "Necesitamos confirmar tu dirección de correo, accede al enlace que te hemos enviado a:",
                nextToValidationExplain: "Una vez validada, podrás iniciar sesión y seguir con el proceso de registro.",
                okButton: "Entendido",
            },
            createProducerAccount: {
                mainHeader: "Hola",
                options: "Has hablado con Nadius y te han proporcionado un codigo de acceso?",  
                yes: "Si",
                no: "No",
                description: "{name} esta aplicación es solo para productores. Rellena el siguiente formulario y nos pondremos en contacto.",
                iWantToBe: "Sí, quiero continuar el registro como productor/a",
                iAccept: "Sí, accepto [la política de privacidad](https://productor.nadius.cat/es/privacy) y los [terminos y condiciones del productor/a](https://productor.nadius.cat/es/terms)",
                createAccount: "Crear cuenta",
                invalidCode: "El codigo no es correcto",
                errorInDataDesc: "Parece que hay errores en los datos introducidos",
                form: {
                    accessCode: "Codigo de acceso",
                    enterCode: "Enviar codigo",
                    website: "Pagina Web",
                    websiteDesc: "El enlace a su pagina web - dejalo vacio si no tenéis",
                    producerName: "Nombre de la marca",
                    producerNameDesc: "El nombre con el que comercializa sus productos",
                    producerNamePlaceholder: "Cal Nadius",
                    message: "Actividad y mensaje",
                    messageDesc: "Explicanos cuál es su actividad y dejanos qualquier comentario que quiera enviarnos",
                    messagePlaceholder: "Soy hortelano, produzco hortalizas ecológicas. Me gustaría unirme a Nadius para formar parte de la revolución del modelo de consumo!",

                }
            },
            index: {
                title: "Inicio",
                hello: "Hola {name}",
                nameComodin: "Productor/a",
                text: "Esta app permite gestionar tus productos en la plataforma de Nadius.",
                downloadText: "No eres un/a productor/a?",
                downloadButtonText: "Descargar la app para clientes",
                textRegistered: "Para continuar con el registro, accede a tu correo {email} y haz clic en el enlace para confirmarlo, luego inicia sesión",
                downloadTextRegistered: "No eres un/a productor/a?",
                downloadButtonTextRegistered: "Descargar la app para clientes",
                registerButton: "Registrarse",
                loginButton: "Iniciar Sesión",
                registerAlert: {
                    title: "Aún no has terminado",
                    description: "No has terminado el registro de {email}, estas seguro que quieres registrar otra cuenta?",
                    confirmButton: "Si, quiero registrar otra",
                    cancelButton: "Cancelar",
                }
            },
            nextSteps: {
                mainHeader: "Mi perfil de productor",
                firstStep: "Completar perfil",
                secondStep: "Verificación de Nadius",
                thirdStep: "Listo!",
                description: "Completa la siguiente información para poder validar su inscripción a Nadius",
                companyForm: "Rellenar el formulario de empresa",
                kycForm: "Documentos de identificación fiscal",
                kycFormDisabled: "Es necesario rellenar primero el formulario de empresa",
                deliveryForm: "Establecer las zonas y los parámetros de envío",
                publicProfile: "Completar tu perfil público",
                addProducts: "Añadir mínimo un producto",
                buttonText: "Rellenar",
                addMore: "Añadir otro",
                add: "Añadir",
                submit: "Enviar para revisión",
                startText: "Empezar a vender",

                validating: "Estamos validando la información, te informaremos de cualquier cambio!",


                onSumbitInvalidStatus: "No hace falta volver a validar los datos porque ya han sido validados.",
                onGoLiveInvalidStatus: "Aún no puedes empezar a vender con Nadius.",
                errorDeliveryPending: "Hace falta la información de la política de envio para que el equipo de Nadius pueda hacer la validación.",
                errorCompanyPending: "Hace falta la información de la empresa para que el equipo de Nadius pueda hacer la validación.",
                errorProductPending: "Hace falta la información de como mínimo 1 producto para que el equipo de Nadius pueda hacer la validación.",
                errorProfilePending: "Hace falta la información del perfil publico para que el equipo de Nadius pueda hacer la validación.",

            },
            welcomeProcess: { 
                title:"Bienvenido al proceso de selección de Nadius",
                description:"Nos tomamos muy en serio la selección de productores para verificar que los productos sean de calidad y ofrecer una buena experiencia de compra para los consumidores.",
                subtitle:"Es muy senzillo y solo te llevara unos minutos",
                step1Title:" 1. Cuéntanos que haces",
                step1Description:"Completa el formulario para que podamos conocerte",
                step2Title:"2. Validamos la información",
                step2Description:"Estudiaremos al detalle tu información para valorar tu inscripción en Nadius",
                step3Title:"3. Crea tu tienda online",
                step3Description:"Estudiaremos al detalle tu información para valorar tu inscripción en Nadius",
                step4Title:"4. Empieza a vender",
                step4Description:"Todo preparado, gracias a Nadius podrás vender tus productos",
                startButton: "¿EMPEZAMOS?",
            },
            language: {
                button: "Siguiente",
                welcome: "Bienvenido a Nadius",
                first: "Primero de todo, permítenos personalizar su experiencia",
                language: "Que idioma quieres utilizar en la plataforma?",
                catalan: "Català",
                spanish: "Castellano"
            },
        },
        order: {
            details: {
                state: "Estado",
                update: "Actualización",

                pendiente: "Pendiente",
                preparing: "Preparación",
                transit: "Tránsito",
                delivered: "Entregado",
                created: "Creación",
                cancelled: "Cancelada",
                dispute: "Incidencia",
                pickupReady: "Listo para recoger",
                pickupDone: "Recogido",
                pickupHours: "Horas",
                pickupDirection: "Dirección",
                pickupInfo: "Información de recogida",
                pickupPrice: "Recogida",
                userData: "Datos de contacto",

                cancelChange: "Cancelar",
                changeButton: "Actualizar",
                sendMessage: "Enviar mensaje",
                sendingMessage: "Enviando...",
                message: "Mensaje",
                cancelOrder: "Cancelarla",
                respond: "Responder",
                sendAgain: "Enviar otro mensaje",
                contactDescription: "El cliente recibirá un aviso de este mensaje",
                max300: "Máximo 300 caracteres",

                yourMessage: "Tú",
                clientMessage: "Cliente",

                noMessages: "No hay ningún mensaje",
                messagesTitle: "Mensajes",

                cancelOrder: "Cancelarlo",
                cancelType: "Selecciona la razón",

                cancelIncorrectProducts: "Producto/s incorrecto/s",
                cancelNoStock: "No hay stock",
                cancelOther: "Otra",

                cancelCharge: "Selecciona la razón",
                cancelOtherTitle: "Danos más detalles",
                cancelPlaceholder: "Porque...",
                cancelReason: "Razón",

                cancelDisclaimer: "Ya se ha procesado el pago, por este motivo se le reembolsará al cliente el importe completo y a ti se te descontará ",
                cancelDisclaimerContinue: ", que es exactamente lo que nos cuesta procesar el pago.",
                cancelContact: "En caso de ser un problema nuestro, indícalo y nos pondremos en contacto contigo.",

                controlPanel: "Panel de control",
                disputeTitle: "Incidencia",
                disputeSolvedStatus: "Resuelta",
                disputeSolvedPending: "Pendiente",
                updateState: "Actualiza el estado",
                disputeResolved: "Marcar como resuelta",
                disputeResolvedDescription: "Se marcara como resuelta por tu parte y si el cliente no cree lo contrario se cerrará.",
                acceptOrder: "Aceptar pedido",
                markAsDelivered: "Marcar como entregado",
                editSendingData: "Modificar envío",
                acceptOrderDescription: "Una vez aceptada, se enviará un mensaje al cliente avisando de este cambio de estado",

                sendingData: "Datos de envío",
                summaryTitle: "Resumen",
                units: "Unidades",
                name: "Nombre",
                total: "Total",
                sendingPrice: "Envío",
                totalPrice: "Precio total",
                free: "Gratuito",

                direction: "Dirección",
                orderNumber: "Número de pedido",
                orderAggregatedNumber: "Número de pedido conjunto",

                notArrived: "No ha llegado el producto",
                notCorresponding: "Lo que he recibido no corresponde con lo que pedí",
                badStatus: "Uno o múltiples productos no estan en buen estado",
                other: "Otro",
                typeDispute: "Tipo",
                disputeMessage: "Mensaje del cliente",
                sending: "Enviando...",
                loading: "Cargando...",

                contactClient: "Contactar cliente",
                contactClientDescription: "El mensaje se enviara a través de Nadius",

                markAsSended: "Marcar como enviado",
                markAsSendedDescription: "Se enviara un mensaje al cliente avisando de este cambio de estado",
                markAsDelivered: "Marcar como entregado",
                markAsDeliveredDescription: "En caso de no haberse entregado, el cliente podrá crear una incidéncia y se pausará el procesamiento del pedido hasta que se resuelva.",
                canContact: "Me podás contactar...",
                messageToClient: "Mensaje al cliente",
                messageToClientHelp: "Explica como el cliente puede realizar el seguimiento. (máx. 150)",
                name: "Nombre",
                nameOfCompany: "Nombre de la compañia que hará el envío",
                otherCompany: "Otra",
                company: "Empresa",
                extern: "Externa",
                sendOrder: "Enviar pedido",

                oneDay: "24h",
                oneTwoDays: "24/48h",
                threeToFour: "48/72h",
                oneWeek: "1 semana",

                moreThanOneWeek: "Más de una semana",
                contactPhone: "Teléfono de contacto",
                method: "Método",
                personally: "Personalmente",
                pickerPlaceholder: "Selecciona una opción",
                sendingTime: "Tiempo de envío",
                sendingDate: "Día de reparto",
                idTracking: "ID de seguimiento",
                idTrackingHelp: "Identificador proporcionado por la compañia",
                deliveryDay: "Día y horario de reparto",
                ownMessage: "Mensaje tuyo",
                selectSendingMethod: "Selecciona si el envío lo realizáis vosotros o una empresa externa.",
            },
            list: {
                headerBackTitle: "Resumen",
                disputes: "Incidencias",
                pending: "Pendientes",
                preparing: "Preparándose",
                transit: "En tránsito",
                delivered: "Entregados",
                title: "Todos los pedidos",
                canceled: "Cancelados",

                quantity: "Cantidad",
                total: "Total",

                disputesTitle: "Incidencias",
                pendingTitle: "Pendientes",
                preparingTitle: "En preparación",
                transitTitle: "En tránsito",
                deliveredTitle: "Entregados",
                canceledTitle: "Cancelados",
                emptyOrders: "Aún no has recibido ningún pedido"
            }
        },
        errorsDescription: {
            emptyField: "El campo no puede estar vacio",
            wrongDecimalFormat: "El campo no está en el formato adecuado: 00.00",
            invalidCharacters: "El campo contiene caracteres inválidos",
            invalidValue: "El campo contiene un valor inválido",
            enumMismatch: "Debes escoger una opción válida",
            noMatch: "El valor esperado no coincide con el introducido"
        },
        producerOptions: {
            profile: "Mi perfil",
            products: "Mis productos",
            orders: "Pedidos",
            groups: "Grupos",
            finance: "Finanzas",
        },
        finances: {
            kycDone: {
                headerTitle: "En proceso",
                mainTitle: "Estamos validando los datos introducidos...",
                verificationText: "En caso de tener algun problema nos pondremos en contacto contigo.",
                secondaryText: "Si quieres ponerte en contacto con nostros, escríbenos a hola@nadius.cat",
                okButton: "Entendido"
            },
            kycForm: {
                selectTitle: "Documento a enviar",
                store: "Guardar",
                shareholderDeclaration: "Declaración de accionistas",
                shareholderDeclarationDesc: "Declarar a todos los accionistas/administradores con más o igual 25% del capital o derechos de voto de la empresa.",
                registerProof: "Prueba de registro",
                statutes: "Estatutos",
                pasaport: "Pasaporte",
                drivingLicence: "Carnet de conducir",
                residencePermit: "Permiso de residencia",
                selectTypeOfDocument: "Selecciona una opción",
                labelRegister: "Extracto del Registro Mercantil Central",
                labelRegistrators: "Extracto de Registradores de España",
                labelModel600: "Modelo 600",
                lessThan3Months: "El documento tienen que tener una fecha menor a 3 meses",
                lessThan3MonthsResolution: "El documento de la resolucón tiene que tener una fecha menor a 3 meses",
                labelRNA: "Prueba registro en el RNA (Registro Nacional de Asociaciones)",
                labelRegisterSoletrader: "Resolución alta de autónomo",
                labelFiscalCard: "Tarjeta de identificación fiscal",
                addAnotherUBO: "Añadir otro accionista",
                delete: "Eliminar",
                confirm: "Confirma la acción",
                confirmDesc: "Una vez eliminado, se perderan los datos introducidos",
                birthplace: "Lugar de nacimiento",
                nameAndSurnames: "Nombre y apellidos",
                namePlaceholder: "Ej. Jose",
                surnamePlaceholder: "Ej. Garcia",
                street: "Calle",
                streetPlaceholder: "Ej. Calle de la Villa, 23, 1a",
                birthday: "Fecha de nacimiento",
                nationality: "Nacionalidad",
                city: "Ciudad",
                country: "País",
                bannerDescription: "En caso de tener cualquier problema con el formulario o prefieres enviarlo por correo, ponte en contacto con nosotros.",
                identityVerification: "Verificación de identidad",
                unableFindDocument: "El fichero no existe",
                missingDocuments: "Faltan documentos.\nRevisa que se hayan guardado correctamente",
                reviewTab: "revisar",
                invalidDate: "La fecha seleccionada no es correcta",
                cancelAlert: "Cancelar",
                disclaimer: "Todos los datos de este formulario son gestionados por la entidad encargada de procesar los pagos y para prevenir fraude. En ningún caso se usaran con otro fin.",
                identityProof: "Representante legal",
                identityProofDescription: "Se necesita un documento que verifique la identidad del representante legal",
                possibleDocumentsLimitation: "Solo son válidos los documentos con formato pdf, .jpeg, .jpg, .gif y .png."
            },
            TRANSFER: "Transferencia",
            PAYIN: "Ingreso",
            PAYOUT: "Transferencia a tu cuenta bancaria",
            at: "A las",
            order: "Pedido",
            transferButton: "Transferir",
            modalTitle: "Realizar transferencia",
            available: "Disponible:",
            continue: "Continuar",
            yesterday: "Ayer",
            today: "Hoy",
            payIn: "Ingreso",
            payOut: "Transferencia a tu banco",
            noTransfers: "No hay ninguna transacción",
            yourSalary: "Tu balance",
            bannerKYC: "Para poder transferir a tu cuenta bancaria, necesitamos que nos proporciones cierta información",
            bannerValidatingKYC: "Los documentos estan siendo validados. Te avisaremos tan buen punto hayamos terminado.",
            endReached: "No hay más",
            fillForm: "Rellenar formulario",
            maxTransactions: "Solo puedes ver las últimas {maxTransactions} transacciones.",
            transfer: {
                amountExceed: "El valor excede a la cantidad disponible",
                payoutDelay: "La transferencia puede tardar entre 1 a 7 días en llegar"
            }
        },
        account: {
            security: {
                alertTitle: "Cambiar la contraseña",
                alertDescription: "Recibirás un correo para poder efectuar el cambio",
                cancel: "Cancelar",
                ok: "Cambiar",
                headerTitle: "Hola",
                titlePassword: "Contraseña",
                changePassword: "Cambiarla",
                loadingModalText: "Enviando correo",
                successModalText: "Correo enviado"
            },
            userInfo: {
                noData: "Sin datos",
                headerTitle: "Datos de usuario"
            },
            userInfoEdit: {
                nameAndSurnames: "Nombre y apellidos",
                name: "Nombre",
                surname: "Apellidos",
                email: "Correo electrónico",
                emailTitle: "Correo",
                phone: "Teléfono",
            },
            language: {
                language: "Lenguage"
            }
        },
        choose: {
            title: "Ajustes",
            alertTitle: "Quieres cerrar la sesión?",
            alertDescription: "Tendrás que volver a introducir de nuevo las credenciales",
            alertCancel: "Cancelar",
            alertConfirm: "Cerrar sesión",
            producerProfile: "Perfil público",
            sellingZones: "Zonas de envío",
            pickupPoints: "Punto de recogida",
            companyForm: "Datos fiscales",
            userSettings: "Tu usuario de Nadius",
            supportContact: "Contactar con soporte",
            closeSession: "Cerrar sesión",
            closing: "Cerrando",
        },
        unit: {
            u: "ud",
            pkg: "pack",
            mg: "mg",
            g: "g",
            kg: "kg",
            ml: "ml",
            cl: "cl",
            l: "l"
        },
        productForm: {
            classification: "Clasificación",
            languagesDesc: "Pulsa el botón 'Lenguajes' para añadir o eliminar",
            headerTitleNew: "Nuevo producto",
            kg: "Kilogramo",
            g: "Gramo",
            mg: "Miligramo",
            l: "Litro",
            ml: "Mililitro",
            cl: "Centilitro",
            u: "Unidad",
            name: "Nombre del producto",
            nameDesc: "El nombre que identifica el producto",
            namePlaceholder: {
                es: "Queso de cabra (250g)",
                ca: "Formatge de cabra (250g)",
                en: "Goat cheese (250g)",
                gl: "Queso de cabra (250g)",
                eu: "Queso de cabra (250g)",
                ast: "Queso de cabra (250g)",
                oc: "Queso de cabra (250g)",
                an: "Queso de cabra (250g)",
            },
            defaultCategoryPlaceholder: "Selecciona una categoría",
            defaultSubcategoryPlaceholder: "Selecciona una subcategoría",
            description: "Descripción del producto",
            descriptionDesc: "Una explicación del producto, explica a los consumidores de que se trata, como lo elaboras, que formas hay de consumir-lo, como debe almacenarse...",
            descriptionPlaceholder: {
                es: "Elaboramos el queso de cabra...",
                ca: "Elaborem el formatge de cabra...",
                en: "We elaborate the goat cheese...",
                gl: "Elaboramos el queso de cabra...",
                eu: "Elaboramos el queso de cabra...",
                ast: "Elaboramos el queso de cabra...",
                oc: "Elaboramos el queso de cabra...",
                an: "Elaboramos el queso de cabra...",
            },
            ingredientsOptions: {
                image: "Añadir como imagen",
                text: "Añadir como texto ",
                noapply: "Marcar como no requerido",
            },
            imageShouldContainIngredientsDisclaimer: "En caso que el producto lo requiera, añade una imagen con el listado de ingredientes.",
            productManagment: "Gestión",
            priceType: "Tipo de precio",
            priceSection: "Precio y formato",
            nameDescSection: "Nombre y descripción",
            price: "Precio total",
            priceDefault: "Precio previo",
            priceOnSale: "Precio oferta",
            priceOnSaleInfo: "El precio final del producto en oferta",
            priceTypeLabelNoSale: "Sin oferta",
            priceTypeLabelOnSale: "En oferta",

            amountSection: "Cantidad por unidad",
            amountType: "Tipo de cantidad",
            amountFrom: "Desde",
            amountMax: "Hasta",
            amountUnit: "Unidad",
            amountFixed: "Cantidad exacta",
            amountRange: "Cantidad variable",

            weightSection: "Peso total",
            weight: "Peso total del producto",
            weightUnit: "Unidad",
            weightDesc: "Peso del producto y embalaje. Este valor se utiliza para calcular y optimizar el coste de envío.",

            priceDescWithSale: "El precio anterior por unidad de formato",
            priceDesc: "El precio final por unidad de formato",
            priceUnit: "Formato",
            packUnits: "Unidades en el pack",
            packUnitsInfo: "Cuantas unidades lleva un paquete",
            amount: "Cantidad",
            amountDesc: "La cantidad neta que incluye 1 unidad",
            labels: "Etiquetas",
            labelsHint: "Pulse el boton \"Añadir\" para añadir atributos al producto",
            allergens: "Alérgenos",
            allergensHint: "Pulse el boton \"Añadir\" para añadir alérgenos al producto",
            stock: "Cantidad en estoc",
            stockDesc: "Cuantos productos puedes subministrar en total.",
            category: "Categoría de producto",
            subcategory: "Subcategoría de producto",
            categoryDesc: "Puedes sugerirnos nuevas categorias, mandanos un correo!",
            images: "Imagenes",
            imagesHint: "Pulse el boton \"Añadir\" para añadir imagenes del producto",
            disableButton: "Deshabilitar producto",
            ownMade: "Soy el productor del producto",
            enableButton: "Habilitar producto",
            deleteButton: "Eliminar producto",
            errorsDescription: {
                imageNumberExceded: "Solo un maximo de 5 imagenes pueden ser añadidas.",
                imageMinRequired: "Debes añadir al menos 1 imagen del producto",
                invalidTags: "Las etiquetas seleccionadas no son válidas",
                invalidCategory: "La categoría seleccionada no son válida",
                invalidsubcategory: "La subcategoría seleccionada no son válida",
                invalidImage: "Por favor, selecciona otra imagen",
                priceSaleMustBeLowerThanPrice: "El precio en oferta debe ser menor al precio anterior",
                priceMustBeHigherThan0: "El precio debe ser mayor a 0",
                packUnitsMustBeHigherThan1: "Las unidades en el pack deben ser mayor a 1",
                amountMustBeHigherThan0: "La cantidad debe ser mayor a 0",
                amountMaxMustBeHigherThanAmount: "La cantidad máxima debe ser mayor a la cantidad mínima",
            },
            authError: "Operación no autorizada.\n\nContacta con nosotros si se trata de un error.",
            errorInDataDesc: "Parece que hay errores en los datos introducidos",
            info: {
                disableButton: "Cuando el producto esta deshabilitado, ningún usuario puede verlo. Podra habilitarlo de nuevo cuando quiera.",
                deleteButton: "Cuando el producto es eliminado, deja de estar disponible. Esta operación no puede deshacerse.",
                ownMadeTrue: "La selección actual indica que usted produce y comercializa este producto.",
                ownMadeFalse: "La selección actual indica que usted NO produce pero comercializa este producto.",
            },
            deleteProductAlertTitle: "Eliminar Producto",
            deleteProductAlertDesc: "Se eliminará definitivamente, no podrás deshacer esta operación.",
        },
        profile: {
            about: {
                disclaimerInformation: "La información presente en esta vista ha sido añadida por "
            },
            index: {
                headerBackTitle: "Ajustes",
                viewProfile: "Ver perfil",
                header: "Tu perfil público",
                profileHeaderTitle: "Cabecera",
                pageDescription: "Así es como los clientes ven la cabezera, la targeta y tu perfil.",
                cardInResults: "Tarjeta",
                modifyData: "Modificar tus datos"
            },
            form: {
                generalTitle: "Información general",
                locationTitle: "Ubicación",
                contactTitle: "Contacto",
                profileTitle: "Perfil",
                mainHelper: "Selecciona el apartado a modificar",
                title: "Editar perfil",
                name: "Nombre",
                city: "Ciudad, Región",
                postalCode: "Código postal",
                street: "Dirección",
                streetPlaceHolder: "Ej. Calle de la Via, 23, 1A",
                nameDesc: "El nombre del productor de este perfil",
                namePlaceholder: "Productor de Quesos",
                slogan: "Eslogan o subtitulo",
                sloganDesc: "Max. 100 caracteres",
                sloganPlaceholder: "Haciendo los mejores quesos desde 1970",
                description: "Contenido",
                descriptionDesc: "Max. 1000 caracteres",
                descriptionPlaceholder: "Explica a los consumidores quien sois, que hacéis, cual es vuestra historia...",
                email: "Correo electronico",
                emailDesc: "Correo electronico con el que quieres que te contacten los clientes",
                telephone: "Teléfono",
                telephoneDesc: "Teléfono de contacto para los clientes",
                website: "Página web",
                websiteDesc: "Página web propia",
                profileImage: "Imagen de perfil",
                selectButton: "Seleccionar imagen",
                coverImage: "Portada",
                galery: "Galeria",
                images: "Imagenes",
                imagesHint: "Pulse el boton \"Añadir\" para añadir imagenes",
                mapHelp: "Pulsa en el mapa para modificar la ubicación",
                errorsDescription: {
                    imageNumberExceded: "Solo un maximo de 5 imagenes pueden ser añadidas.",
                    imageMinRequired: "Debes añadir al menos 1 imagen",
                    invalidImage: "Por favor, selecciona otra imagen",
                    invalidCoordinates: "Por favor, introduzca unas coordenadas correctas"
                },
                errorInData: "No se ha podido guardar",
                errorInDataDesc: "Parece que hay errores en los datos introducidos",
                dataUploaded: "Datos recibidos correctamente",
                dataUploadedMessage: "Properamente van a ser revisados y acceptados",
            },
            map: {
                mapInfoHelper: "Manten pulsado para seleccionar la ubicación.",
                producerLocation: "Donde estás?",
                setMarker: "Manten pulsado para seleccionar una ubicación",
                dragMarker: "Manten pulsado o arrastra el marcador para cambiar la ubicación",
                saveLocation: "Seleccionar ubicación"
            }
        },
        zones: {
            map: {

                store: "Guardar",

                alertChildTitle: "Conflicto",
                alertChildDescription: "Hay subzonas seleccionadas en esta zona, quieres eliminarlas?",
                alertDelete: "Eliminar",
                alertCancel: "Cancelar"
            },
            details: {
                back: "Zonas",
                zoneNotSelected: "Tienes que seleccionar mínimo una zona",

                province: "Provincia",

                whereDoesItApplies: "Ubicaciones que aplica",
                personalized: "Personalizado",
                selectOnePossible: "Selecciona una de las siguientes opciones",
                whereDoesItAppliesDesc: "Seleccionando 'Personalizado', podrás escoger en detalle a nivel de población hasta Provincia.",
                personalizedButton: "Define la zona",

                actionsTitle: "Acciones",
                deleteButton: "Eliminar",
                deleteDesc: "Al eliminar, puede que durante un periodo limitado aún tenga efecto.",

                nameTitle: "Nombre",
                name: "Nombre de la Zona",
                namePlaceHolder: "Zona de Barcelona",
                withoutName: "Zona sín nombre",

                price: "Precio",
                priceDisabled: "El precio no tiene efecto debido a la selección del envío gratuíto",
                suplementEvery: "Por cada",
                priceWeight: "Suplemento de",
                addSuplement: "Añadir suplemento por peso",
                suplementDesc: "Es importante definir correctamente el peso por cada producto para que pueda funcionar correctamente.",

                errorInData: "No se ha podido guardar",
                errorInDataDesc: "Parece que hay errores en los datos introducidos",
                headerTitle: "Precio y zonas de envío",
                sellZonesTitle: "Zonas de envio",
                deliveryPrice: "Precio de envio",
                deliveryPriceDesc: "El precio de envio por pedido",

                alwaysFree: "Siempre gratuito",
                notAlwaysFree: "A partir de cierto precio",
                neverFree: "Nunca gratuito",

                freeDeliveryTitle: "Envío gratuito",
                freeDeliverySelect: "Modalidad",
                freeDelivery: "Precio mínimo para envio gratuito",
                freeDeliveryDesc: "Precio del pedido mínimo para que el cliente pueda disfrutar de un envio gratuito",

                minOrder: "Pedido mínimo",
                minOrderSelect: "Modalidad",
                noMinOrder: "Sin pedido mínimo",
                withMinOrder: "Con pedido mínimo",
                minOrderDesc: "Precio minimo que tiene que tener el pedido",

                deliveryMethods: "Método de envío",
                deliveryMethodSelect: "Modalidad",
                deliveryMethodDefault: "Seleccionar una opción",
                deliveryMethodPersonally: "Personalmente",
                deliveryMethodExternal: "Empresa externa",
                
                sellZonesDesc: "Selecciona a continuación las zonas puedes enviar tus productos",
                timeTitle: "Tiempo de envio",
                time: {
                    selectOne: "Selecciona una opción",
                    oneDay: "1 día",
                    oneTwoDays: "1-2 días",
                    threeToFour: "3-4 días",
                    oneWeek: "1 semana",
                },
                daysTitle: "Selecciona los días que realizas los envios",
                deliveryDaysHelp: "Si no tienes un horario definido, no hace falta seleccionar ningun día",
                days: {
                    monday: "Lunes",
                    tuesday: "Martes",
                    wednesday: "Miércoles",
                    thursday: "Jueves",
                    friday: "Viernes",
                    saturday: "Sábado",
                    sunday: "Domingo",
                },
                shortDays: {
                    monday: "L",
                    tuesday: "M",
                    wednesday: "Mi",
                    thursday: "J",
                    friday: "V",
                    saturday: "S",
                    sunday: "D",
                },
                errors: {
                    freeFromBiggerThanMinOrder: 'Dada la selección actual, no puedes poner el envio gratuito menor o igual al precio mínimo por pedido.'
                }
            },
            list: {

                back: "Ajustes",

                maxDesc: "de 10 disponibles",

                extern: "Externo",
                deliveryIn: "Envío en",

                noneYet: "Aún no tienes ninguna zona definida",
                noneYetDesc: "Pulsa el botón 'Añadir una zona' para crearla",

                locationNotYetTitle: "Aún no has establecido tu ubicación",
                locationNotYetDesc: "Accede al apartado de Perfil público, pulsa modificar y establece la ubicación",
                navigate: "Acceder a Perfil Público",

                price: "Precio",
                suplement: "Suplemento",
                freeFrom: "Gratuíto desde",
                minOrder: "Pedido mínimo de",
                alwaysFree: "Envío gratuíto",
                noFreeFrom: "Nunca gratuíto",
                headerTitle: "Zonas de entrega",
                noZones: "No hay zonas definidas",
                addNew: "Añadir una zona",
                pickupTitle: "Recogida en origen",
            },
        },
        pickup: {
            back: "Ajustes",
            name: "Nombre del punto",
            nameTitle: "Nombre",
            namePlaceHolder: "Nuestras instalaciones",
            nameHelp: "El nombre que verán los clientes como punto de recogida",
            headerTitle: "Punto de recogida",
            activate: "Habilitar punto de recogida",
            activateTitle: "Habilitar",
            possibleDays: "Selecciona los días que se pueda recoger",
            timeTitle: "Días y horarios",
            store: "Guardar"
        },
        profileSections: {
            name: "Nombre",
            slogan: "Eslogan",
            description: "¿Quién somos?",
            images: "Imágenes",
            contact: "Contacto"
        },
        profileInfos: {
            name: "Nombre de la marca",
            slogan: "Eslogan de la marca",
            description: "¿Quién sois? ¿Que hacéis? ¿Cuál es vuestra historia? ...",
            images: "Pulsa el boton \"Añadir\" para añadir imágenes de lo que haceis, donde trabajais, como lo haceis ..."
        },
        locale: {
            es: "Español",
            ca: "Catalan",
            en: "Inglés",
            gl: "Gallego",
            eu: "Euskera",
            ast: "Asturiano",
            oc: "Occitano",
            an: "Aragonés"
        },
        sended: "¡Enviado!",
        success: "Hecho!",
        loading: "Cargando...",
        sellZones: {
            headerTitle: "Zonas de venda",
            salePlaces: "Selecciona tus ubicaciones de venta:",
            saleZonesUpdated: "Las zonas de venta se han actualizado correctamente",
            saleZonesError: "Error al actualitzar las zonas de venta",
            saleZonesErrorDesc: "Pruebalo más tarde, si el error persiste por favor contáctenos.",
        },
        message_uploadOperationTakingLong: "La operación esta tardando demasiado, pulse el boton para cancelarla",
        companyForm: {
            common: {
                backButton: 'Atrás',
                continueButton: "Continuar",
                Direction: "Nombre de la calle y número",
                header: "Datos de la empresa",
                errorInData: "No se ha podido guardar",
                success: "Datos de la compañia guardados correctamente"
            },
            companyForm: {
                generalInfo: "Información general",
                legalRepresentative: "Representate legal",
                finantialData: "Datos financieros"
            },
            fiscal: {
                header: "Datos fiscales",
                readOnly: "Si quieres modificar alguno de estos datos, contactanos en hola@nadius.cat",
                error: "No se han recibido los datos correctamente"
            },
            general: {
                Identification: "Identificación",
                Name: "Nombre de la empresa",
                NIF: "Número de identificación fiscal - NIF",
                LegalPersonType: "Forma jurídica",
                Contact: "Contacto de la empresa",
                Email: "Correo electrónico",
                PhoneNumber: "Número de télefono",
                HeadquartersAddress: "Dirección física",
                legalPersonType: {
                    business: "Empresa",
                    organization: "Organización",
                    soletrader: "Autónomo"
                },
                NameInfo: "Nombre fiscal de la empresa.",
                NIFInfo: "Número de identificación físcal.",
                EmailInfo: "Se utilizará para comunicaciones legales y/o organizativas.",
                HeadquartersStreetInfo: "Dirección física legal de la empresa."
            },
            finantial: {
                FinantialData: "Datos Bancarios",
                OwnerName: "Titular de la cuenta",
                IBAN: "Número de cuenta - IBAN",
                OwnerAddress: "Dirección física",
                ownerAddressHeadquartersChecked: "Utilizar la dirección física de la empresa",
                ownerAddressLegalRepresentativeChecked: "Utilizar la dirección física del representante legal",
                nameInfo: "Nombre del titular de la cuenta donde se van a recibir los ingresos",
                ibanInfo: "IBAN de la cuenta donde se van a recibir los ingresos",
                direction: "Dirección asociada a la cuenta bancaria"
            },
            legalRepresentative: {
                header: "Representante Legal",
                name: "Nombre",
                surnames: "Apellidos",
                Birthday: "Fecha de nacimiento",
                Email: "Correo electrónico",
                Nationality: "Nacionalidad",
                CountryOfResidence: "Pais de residencia",
                LegalRepresentativeAddress: "Dirección física",
                legalRepresentativeChecked: "Utilizar la dirección física de la empresa",
                mailInfo: "Sólo se va a utilizar como prueba de identidad de la empresa.",
                addressInfo: "Dirección del representante legal."
            }

        },
        root: {
            screens: {
                Welcome: 'Inicio',
                AccessLogin: 'Iniciar sesión',
                AccessRegister: 'Registro',
                AccessForgot: 'Recuperación de contraseña',
                AccessVerification: "Verificación",
                PrivacyScreen: "Política de privacidad",
                AccessEmailVerification: "Confirmación de email",
                AccessResetPassword: "Restablecer contraseña",
                CreateProducerAccount: "Solicitar acceso",
                WaitingValidation: "Esperando validación",
                Comandes: "Pedidos",
                Orders: "Pedidos",
                NextSteps: "Proceso de registro",
                CompanyForm: "Formulario de empresa",
                KYCForm: "Documentos de verificación",
                KYCSubmit: "En proceso de verificación",
                Productes: "Productos",
                ProducerProfile: "Perfil de productor",
                Business: "Datos fiscales",
                ProfileForm: "Perfil de productor",
                Products: "Productos",
                ProductForm: "Formulario de producto"
            },
            firstTab: "Pedidos",
            secondTab: "Productos",
            thirdTab: "Finanzas",
            lastTab: "Parámetros",
            profileTab: "Perfil productor",
            business: "Datos fiscales",
            productsTab: "Catálogo de productos",
            noInternet: "Estás sin conexión a internet"
        }
    },
    en: {}
};

// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
i18n.fallbacks = true;
i18n.defaultLocale = "es";
