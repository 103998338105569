import React, { useState, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import AsyncStorage from '@react-native-community/async-storage';
import * as Font from 'expo-font';
import i18n from 'i18n-js';
import moment from "moment/min/moment-with-locales"

import { ApolloProvider } from '@apollo/client';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { AppCenter, SplashScreen } from '@platform'
import * as Notifications from '@utilities/notifications';
import { persistCache } from 'apollo3-cache-persist';

import { checkAndInstall } from '@utilities/codePush'
import RootStackScreen from "_navigation";
import { persistor, store } from '_redux/store';
import { AppStyle } from '@style'
import { initializeApp, getAccessToken, AuthProvider, loginUser } from '@realm'
import UpdateScreen from './src/views/scenes/update'
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Platform, StatusBar } from 'react-native';

require('./src/translations')
AppStyle.update('light')

export default function App(props) {

  const [progress, setProgress] = useState(undefined);
  const [isLoading, setLoading] = useState(true);
  const [initScreen, setInitScreen] = useState(undefined);
  const [client, setClient] = useState(undefined);

  const createApolloClient = async () => {
    const customFetch = (uri, options) => {
      return getAccessToken(app).then((token) => {
        options.headers.Authorization = `Bearer ${token}`
        return fetch(uri, options);
      })
    };

    const cache = new InMemoryCache();

    await persistCache({
      cache,
      storage: AsyncStorage,
    });

    return new ApolloClient({
      cache: cache,
      link: new HttpLink({
        uri: 'https://stitch.mongodb.com/api/client/v2.0/app/nexto-nmbza/graphql',
        headers: {
          "Content-Type": "application/json"
        },
        fetch: customFetch
      })
    })
  }

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {

    async function loadResourcesAndDataAsync() {

      try {

        global.app = await initializeApp('nexto-nmbza')

        console.log('Loading fonts')
        // Load fonts
        await Font.loadAsync({
          'Font-ExtraBold': require('@fonts/Titillium/TitilliumWeb-Black.ttf'),
          'Font-Bold': require('@fonts/Titillium/TitilliumWeb-Bold.ttf'),
          'Font-SemiBold': require('@fonts/Titillium/TitilliumWeb-SemiBold.ttf'),
          'Font-Medium': require('@fonts/Titillium/TitilliumWeb-Regular.ttf'),
          'Font-Regular': require('@fonts/Titillium/TitilliumWeb-Regular.ttf'),
          'Font-Light': require('@fonts/Titillium/TitilliumWeb-Light.ttf'),
          'Font-ExtraLight': require('@fonts/Titillium/TitilliumWeb-ExtraLight.ttf'),
          'Nadius-Icons': require('@fonts/nadius-icons.ttf'),
        });

      } catch (e) {
        console.warn(e);
      }

      try {

        let val = await checkAndInstall(() => {
          setLoading('update')
        }, (message) => {
          setProgress(message)
        })

        if (typeof val !== 'undefined') {
          return
        }

      } catch (e) {
        console.warn(e);
      }

      try {

        setClient(await createApolloClient())

        i18n.locale = await loginUser(app, false)
        let user = app.currentUser
        moment.locale(i18n.locale)

        let initialScreen;

        const locale = await AsyncStorage.getItem('@nadius:locale')
        if (locale == null) {
          if (user !== null && typeof user?.customData?.locale === 'undefined') {
            initialScreen = 'Login'
          } else {
            await AsyncStorage.setItem('@nadius:locale', user?.customData?.locale)
          }
        }

        if (typeof initScreen == 'undefined' && user != null && typeof user?.customData !== 'undefined' && user?.customData !== null) {
          if (typeof user.customData.producer === 'undefined' || user.customData.producer == null) {
            initialScreen = 'Login'
          }
        }
        
        if (initialScreen) {
          setInitScreen(initialScreen)
        }

        // Set the locale once at the beginning of your app.
        i18n.fallbacks = true;
        i18n.defaultLocale = "es";

        if (user !== null && AppCenter?.setUserId) {
          AppCenter.setUserId(user.customData === null ? user.id : user.customData._id);

          if (user.customData !== null) {
            await Notifications.register(() => { }, () => { }, 'producer')
          }

        }

      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      }

      SplashScreen.hide?.();
      setLoading(false)

    }
    loadResourcesAndDataAsync();
  }, []);

  if (isLoading === true) {
    return null
  }

  if (isLoading === 'update') {
    return <UpdateScreen
      progress={progress} />
  }

  if (isLoading !== 'update' && typeof client === 'undefined') {
    return null
  }

  const linking = {
    prefixes: ['https://productor.nadius.cat/', 'nadiusPro://'],
    config: {
      screens: {
        Login: {
          screens: {
            Welcome: {
              initialRouteName: 'AccessLogin',
              screens: {
                AccessLogin: 'login',
                AccessRegister: 'register',
                AccessForgot: 'forgot',
                AccessVerification: 'verification',
                AccessEmailVerification: 'email/:locale/:token/:tokenId',
                AccessResetPassword: 'reset/:locale/:token/:tokenId'
              }
            },
            TermsScreen: '/:locale?/terms',
            PrivacyScreen: '/:locale?/privacy',
            CreateProducerAccount: 'create',
            WaitingValidation: 'validation',
          }
        },
        Main: {
          initialRouteName: 'NextSteps',
          screens: {
            NextSteps: 'pro-register',
            ProducerProfile: 'profile',
            KYCForm: 'documents',
            KYCSubmit: 'validating',
            CompanyForm: 'company',
            Comandes: {
              initialRouteName: 'Orders',
              screens: {
                Orders: 'orders',
                OrderDetails: 'order/:_id',
              }
            },
            Productes: {
              initialRouteName: 'Products',
              screens: {
                Products: 'products/',
                ProductForm: 'product/:id'
              }
            },
            Finances: {
              screens: {
                Finances: 'finances/'
              }
            },
            ProducerProfile: {
              screens: {
                ProfileForm: 'profile'
              }
            },
            Business: 'business',
            Usuari: {
              screens: {
                Choose: 'user'
              }
            }
          }
        }
      },
    }
  };

  StatusBar.setBarStyle('dark-content')
  if (Platform.OS === 'android') {
    StatusBar.setTranslucent(true)
    StatusBar.setBackgroundColor('transparent')
  }

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          <AuthProvider>
            <SafeAreaProvider>
              <NavigationContainer linking={linking} >
                <RootStackScreen initialRouteName={initScreen} />
              </NavigationContainer>
            </SafeAreaProvider>
          </AuthProvider>
        </ApolloProvider>
      </PersistGate>
    </ReduxProvider>
  );
}
